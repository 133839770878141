import {
  cloneScheduler,
  closeSchedulers,
  createScheduler,
  getCurrentExecution,
  getSchedulers,
  importScheduler,
  runNowScheduler,
  runProjectJob,
  terminateProjectJob,
  updateScheduler,
} from "../../api/endpoints";
import { createMutation, createQuery } from "./apiHooks";

export const SCHEDULER_QUERY_KEY = ["Schedulers", "Get"];

export const useGetSchedulers = createQuery(getSchedulers, {
  queryKey: SCHEDULER_QUERY_KEY,
});

export const useCreateScheduler = createMutation(
  createScheduler,
  {
    mutationKey: ["Schedulers", "Create"],
  },
  SCHEDULER_QUERY_KEY,
);

export const useCloseSchedulers = createMutation(
  closeSchedulers,
  {
    mutationKey: ["Schedulers", "Close"],
  },
  SCHEDULER_QUERY_KEY,
);

export const useCloneScheduler = createMutation(
  cloneScheduler,
  {
    mutationKey: ["Schedulers", "Clone"],
  },
  SCHEDULER_QUERY_KEY,
);

export const useImportScheduler = createMutation(
  importScheduler,
  {
    mutationKey: ["Schedulers", "Import"],
  },
  SCHEDULER_QUERY_KEY,
);

export const useUpdateScheduler = createMutation(
  updateScheduler,
  {
    mutationKey: ["Schedulers", "Update"],
  },
  SCHEDULER_QUERY_KEY,
);

export const useRunNowScheduler = createMutation(
  runNowScheduler,
  {
    mutationKey: ["Schedulers", "RunNow"],
  },
  SCHEDULER_QUERY_KEY,
);

export const useTerminateProjectJob = createMutation(
  terminateProjectJob,
  {
    mutationKey: ["Schedulers", "TerminateProjectJob"],
  },
  SCHEDULER_QUERY_KEY,
);

export const useRunProjectJob = createMutation(
  runProjectJob,
  {
    mutationKey: ["Schedulers", "RunProjectJob"],
  },
  SCHEDULER_QUERY_KEY,
);

export const useGetCurrentExecution = createQuery(getCurrentExecution, {
  queryKey: ["Schedulers", "CurrentExecution"],
});
