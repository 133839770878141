"use client";

import { Box, Typography } from "@mui/material";
import { StyledChip } from "./styledChip";

type ComponentProps = {
  items: string[];
  label: string;
};

export const RelatedItemsList = ({ items, label }: ComponentProps) => {
  return (
    <Box display="flex" gap="0.5rem">
      <Typography sx={{ textWrap: "nowrap" }}>{label}</Typography>
      <Box display="flex" gap="0.5rem" flexGrow={1} flexWrap={"wrap"}>
        {items.map((name, index) => (
          <StyledChip key={index} variant="outlined" customColor="neutral.700" label={name} />
        ))}
      </Box>
    </Box>
  );
};
