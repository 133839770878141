"use client";

import { DevTool } from "@hookform/devtools";
import { Stack } from "@mui/material";
import { SxProps, Theme, styled } from "@mui/material/styles";
import { BaseSyntheticEvent, ReactNode } from "react";
import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";
import { isDevelopment } from "../../utils/env";
import { ActionButtonsWrapper, ActionButtonsWrapperProps } from "../materialUI";

interface FormWrapperProps<TFormValues extends FieldValues> {
  children: ReactNode;
  methods: UseFormReturn<TFormValues>;
  onSubmit: (data: TFormValues, event?: BaseSyntheticEvent) => void | Promise<void>;
  dataTestid: string;
  spacing?: number;
  alignItems?: string;
  stickyElementHeight?: string;
  renderActionButtons?: React.ReactNode;
  actionButtonsProps?: ActionButtonsWrapperProps;
  id?: string;
  sx?: SxProps<Theme>;
  [key: string]: unknown;
}

const StyledForm = styled("form")<{
  hasStickyButtonsWrapper: boolean;
  stickyElementHeight: string;
}>(({ hasStickyButtonsWrapper, stickyElementHeight }) => ({
  height: hasStickyButtonsWrapper ? `calc(100% - ${stickyElementHeight})` : "max-content",
  overflow: "auto",
}));

export const FormWrapper = <TFormValues extends FieldValues>({
  children,
  schema,
  methods,
  onSubmit,
  dataTestid,
  renderActionButtons,
  actionButtonsProps,
  alignItems = "stretch",
  stickyElementHeight = "3.5rem",
  spacing = 3,
  ...props
}: FormWrapperProps<TFormValues>) => {
  const hasStickyButtonsWrapper = actionButtonsProps?.variant === "sticky";

  const handleSubmit = methods.handleSubmit((data, event) => {
    onSubmit(data, event);
    // TODO: trimming destroys date strings, temporary disabling it
    // const trimmedData = trimStringValues(data);
    // onSubmit(trimmedData);
  });

  return (
    // TS Performance improvement
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <FormProvider {...(methods as any)}>
      <StyledForm
        data-testid={dataTestid}
        onSubmit={handleSubmit}
        hasStickyButtonsWrapper={hasStickyButtonsWrapper}
        stickyElementHeight={stickyElementHeight}
        {...props}
      >
        <Stack spacing={spacing} alignItems={alignItems} py={1}>
          {children}
        </Stack>
        {renderActionButtons && (
          <ActionButtonsWrapper {...actionButtonsProps}>{renderActionButtons}</ActionButtonsWrapper>
        )}
      </StyledForm>

      {isDevelopment() && <DevTool control={methods.control} placement="top-right" />}
      {/* it breaks form layout in many places so first we need to figure out how to scale it properly */}
      {/* {isDevelopment() && <pre>{JSON.stringify(methods.formState, null, 2)}</pre>} */}
    </FormProvider>
  );
};
