import { createRequest, getEndpoint, getEndpointWithClientParams } from "../requestCreator";

export const getScript = createRequest(getEndpoint("scripts.sub.getOne"));
export const getLinkedMap = createRequest(getEndpoint("scripts.sub.linkedMap"));
export const getLinkedIn = createRequest(getEndpoint("scripts.sub.linkedIn"));
export const getScripts = createRequest(getEndpoint("scripts.sub.get"));
export const getOneScript = createRequest(getEndpoint("scripts.sub.getOne"), {
  pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
});
export const getScriptsCount = createRequest(getEndpoint("scripts.sub.count"));
export const getScriptChangeLog = createRequest(getEndpoint("scriptChangeLog.sub.get"));
export const scriptAutocomplete = createRequest(getEndpoint("scripts.sub.autocomplete"));
export const closeScript = createRequest(getEndpoint("scripts.sub.close"));
export const createScript = createRequest(getEndpoint("scripts.sub.create"));
export const editScript = createRequest(getEndpoint("scripts.sub.update"), {
  pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
});
export const deleteScript = createRequest(getEndpoint("scripts.sub.delete"));
export const patchScript = createRequest(getEndpoint("scripts.sub.update"), {
  pathParams: (params) => ({ id: params.script.id }),
});
export const importScript = createRequest(
  getEndpointWithClientParams<{ file: File }>()("scripts.sub.import"),
  {
    useContentType: false,
    bodyBuilder: (params) => {
      const { file, ...restParams } = params;
      const formData = new FormData();
      if (file) {
        formData.append(file.name, file);
      }
      formData.append("globalExcelOption", restParams.globalExcelOption);
      return formData;
    },
  },
);
export const cloneScript = createRequest(getEndpoint("scripts.sub.clone"));
export const exportScript = createRequest(getEndpoint("scripts.sub.export"), {
  fileRequest: true,
});

export const downloadScriptsDocumentation = createRequest(
  getEndpoint("documentations.sub.downloadFromScripts"),
  {
    fileRequest: true,
  },
);
