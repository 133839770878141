import CloseIcon from "@mui/icons-material/Close";
import DialogTitle, { DialogTitleProps } from "@mui/material/DialogTitle";
import { IconButton } from "../Buttons/IconButton";

interface CustomDialogTitleProps extends DialogTitleProps {
  withCloseIcon?: boolean;
  onClose?: () => void;
}

export const CustomDialogTitle = ({
  children,
  withCloseIcon,
  onClose,
  ...other
}: CustomDialogTitleProps) => {
  return (
    <DialogTitle {...other}>
      {children}
      {withCloseIcon && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
};

export { CustomDialogTitle as DialogTitle };
