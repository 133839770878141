type PathValue<T, Path extends string> = Path extends `${infer Key}.${infer Rest}`
  ? Key extends keyof T
    ? PathValue<T[Key], Rest>
    : never
  : Path extends keyof T
    ? T[Path]
    : never;

export function getNestedValue<T, Path extends string>(
  obj: T,
  path: Path,
): PathValue<T, Path> | undefined {
  if (typeof path.split !== "function") {
    console.warn(`getNestedValue path parameter ${path} is not a string`, path);
    return undefined;
  }
  const keys = path.split(".") as string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let result: any = obj;
  for (const key of keys) {
    if (result && key in result) {
      result = result[key];
    } else {
      return undefined;
    }
  }
  return result;
}
