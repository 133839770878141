"use client";

import { ReactNode } from "react";
import { useTranslations } from "../../translations/useTranslations";
import { Alert, AlertTitle } from "../materialUI";

type FormServerMessageProps = {
  children?: ReactNode;
};

export const FormServerMessage = ({ children }: FormServerMessageProps) => {
  const { t } = useTranslations("Drawer");
  if (!children) return null;
  return (
    <Alert severity="error" sx={{ width: "100%" }}>
      <AlertTitle>{t("DrawerActions.serverError")}</AlertTitle>
      {children}
    </Alert>
  );
};
