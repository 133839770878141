"use client";

import { useIsMutating } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import { DataTestIds } from "../../dataTestIds";
import { Button, ButtonProps, CircularProgress } from "../materialUI";

interface FormSubmitButtonProps extends ButtonProps {
  dataTestid?: string;
}

export const FormSubmitButton = ({
  children,
  type = "submit",
  variant = "contained",
  dataTestid,
  color = "secondary",
  disabled,
  ...props
}: FormSubmitButtonProps) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  const isMutating = useIsMutating();

  const isLoading = isMutating > 0 || isSubmitting;

  return (
    <Button
      data-testid={dataTestid || DataTestIds.FORM_BUTTON_SUBMIT}
      type={type}
      disabled={disabled || isLoading}
      variant={variant}
      color={color}
      {...props}
    >
      {isLoading ? <CircularProgress size={24} /> : children}
    </Button>
  );
};
