import { executionStepLogs, getExecutionStepScreenshots } from "../../api/endpoints/stepLogs";
import { createQuery } from "./apiHooks";

export const useExecutionStepLogs = createQuery(executionStepLogs, {
  queryKey: ["ExecutionStepLogs", "Get"],
});

export const useGetExecutionStepScreenshots = createQuery(getExecutionStepScreenshots, {
  queryKey: ["ExecutionStepScreenshots", "Get"],
});
