"use client";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Button, ButtonGroup, ButtonGroupProps, ButtonProps } from "../../../materialUI";

export type SplitButtonProps = {
  mainButtonProps: ButtonProps;
  secondButtonProps: ButtonProps;
  buttonGroupProps?: ButtonGroupProps;
};

export const SplitButton = ({
  mainButtonProps,
  secondButtonProps,
  buttonGroupProps,
}: SplitButtonProps) => {
  const defaultMainButtonProps = { endIcon: <ArrowForwardIcon />, ...mainButtonProps };
  const defaultSecondButtonProps = { children: <MoreHorizIcon />, ...secondButtonProps };

  return (
    <ButtonGroup variant="contained" {...buttonGroupProps}>
      <Button {...defaultMainButtonProps}>{defaultMainButtonProps.children}</Button>
      <Button {...defaultSecondButtonProps} size="small">
        {defaultSecondButtonProps.children}
      </Button>
    </ButtonGroup>
  );
};
