"use client";
import React, { useContext } from "react";
import { TranslateFunctionWithNamespace } from "./types";

export type TranslationContextType = {
  tWithNamespace: TranslateFunctionWithNamespace;
  locale: string;
  changeLanguage: (lng: string) => Promise<void>;
};

const TranslationContext = React.createContext<TranslationContextType | null>(null);

export const useTranslationContext = () => {
  const context = useContext(TranslationContext);
  if (!context) throw new Error("Component must be rendered under TranslationContext");

  return context;
};

export const TranslationWrapper = ({
  children,
  translationContext,
}: {
  children: React.ReactNode;
  translationContext: TranslationContextType;
}) => {
  return (
    <TranslationContext.Provider value={translationContext}>{children}</TranslationContext.Provider>
  );
};
