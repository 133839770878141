"use client";
import { RefObject, useCallback, useEffect, useState } from "react";

interface ImageDimensions {
  width: number;
  height: number;
}

export const useImageDimensions = (
  containerRef: RefObject<HTMLElement>,
  imageRef: RefObject<HTMLImageElement>,
) => {
  const [imageDimensions, setImageDimensions] = useState<ImageDimensions>({ width: 0, height: 0 });

  // update the image dimensions based on container and image aspect ratios
  const updateImageDimensions = useCallback(() => {
    if (containerRef.current && imageRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      const containerHeight = containerRef.current.clientHeight;
      const imgAspectRatio = imageRef.current.naturalWidth / imageRef.current.naturalHeight;
      const containerAspectRatio = containerWidth / containerHeight;

      let width, height;
      if (imgAspectRatio > containerAspectRatio) {
        width = containerWidth;
        height = containerWidth / imgAspectRatio;
      } else {
        height = containerHeight;
        width = containerHeight * imgAspectRatio;
      }

      setImageDimensions({ width, height });
    }
  }, [containerRef, imageRef]);

  useEffect(() => {
    updateImageDimensions();
    window.addEventListener("resize", updateImageDimensions);
    return () => window.removeEventListener("resize", updateImageDimensions);
  }, [updateImageDimensions]);

  return imageDimensions;
};
