import { createRequest, getEndpoint } from "../requestCreator";

export const getKpis = createRequest(getEndpoint("kpis.sub.get"));
export const deleteKpi = createRequest(getEndpoint("kpis.sub.delete"));
export const createKpi = createRequest(getEndpoint("kpis.sub.create"));
export const updateKpi = createRequest(getEndpoint("kpis.sub.update"));
export const getExecutionStatistics = createRequest(
  getEndpoint("kpis.sub.statistics.sub.execution"),
);
export const getExecutionJobStatistics = createRequest(getEndpoint("kpis.sub.statistics.sub.job"));
