import { Step } from "@ea/shared_types/types";
import {
  CommandDefinitionBase,
  CommandDefinitionBaseType,
  CommandDefinitionRunner,
  CommandDefinitionServer,
  CommandDefinitionWeb,
  CommandPack,
  CommandsAPI,
  CommandsRunnerAPI,
  CommandsServerAPI,
  CommandsWebAPI,
  DEFAULT_PLATFORM_ID,
  initDefaults,
  PlatformWeb,
} from "./ea.internal.types";
import { getEAInternal } from "./utils";

export function setCommandsAPI(api: CommandsAPI) {
  getEAInternal().commands.api = api;
}

type CommandLoader<B extends CommandsAPI, T extends CommandDefinitionBase> = (
  commandsAPI: B,
) => CommandPack<T>;
function registerCommands<B extends CommandsAPI, T extends CommandDefinitionBase>(
  platformId: string,
  version: string,
  loader: CommandLoader<B, T>,
  translations?: any,
) {
  console.log(`Loading commands fields for platform '${platformId}' in version '${version}'`);

  const commandsModule = loader(getEAInternal().commands.api as any);

  if (!getEAInternal().commands.packs[platformId]) {
    getEAInternal().commands.packs[platformId] = {};
  }

  Object.keys(commandsModule).forEach((key) => {
    commandsModule[key]!.platformId = platformId;
    if (getEAInternal().commands.packs[platformId]![key]) {
      // @ts-ignore
      getEAInternal().commands.packs[platformId][key] = {
        ...getEAInternal().commands.packs[platformId]![key],
        ...commandsModule[key],
      };
    } else {
      // @ts-ignore
      getEAInternal().commands.packs[platformId][key] = commandsModule[key];
    }
  });

  if (translations) {
    getEAInternal().commands.translations[platformId] = translations;
  }
}

function getCommandsPack<T extends CommandDefinitionBase>(platformId: string): CommandPack<T> {
  const defaultCommands = getEAInternal().commands.packs[DEFAULT_PLATFORM_ID];
  const platformCommands = getEAInternal().commands.packs[platformId];

  return {
    ...defaultCommands,
    ...platformCommands,
  } as any as CommandPack<T>;
}

export function getAllCommands<T extends CommandDefinitionBase>(): CommandPack<T> {
  return Object.keys(getEAInternal().commands.packs).reduce((container, packId) => {
    container = {
      ...container,
      ...getEAInternal().commands.packs[packId],
    };

    return container;
  }, {});
}

export function getServerCommandsPack(platformId: string) {
  return getCommandsPack<CommandDefinitionServer>(platformId);
}

export function isStepCommandPlatformValid(step: Pick<Step, "platform" | "commandId">): boolean {
  const availableCommands = {
    ...getEAInternal().commands.packs[DEFAULT_PLATFORM_ID],
    ...getEAInternal().commands.packs[getEAInternal().currentPlatform?.id!],
  };

  return !!availableCommands[step.commandId];
}

export function getStepCommand<T extends CommandDefinitionBase>(
  step: Pick<Step, "platform" | "commandId">,
): T {
  const platformId = step.platform ? step.platform.id : DEFAULT_PLATFORM_ID;

  if (
    getEAInternal().commands.packs[platformId] &&
    getEAInternal().commands.packs[platformId]![step.commandId]
  ) {
    return getEAInternal().commands.packs[platformId]![step.commandId] as T;
  }

  return getEAInternal().commands.packs[DEFAULT_PLATFORM_ID]![step.commandId] as T;
}

export function getWebCommandsPack(platformId: string) {
  return getCommandsPack<CommandDefinitionWeb>(platformId);
}

export function getRunnerCommandsPack(platformId: string) {
  return getCommandsPack<CommandDefinitionRunner>(platformId);
}

export function getTranslations() {
  return getEAInternal()
    .platforms.filter((p) => (p as PlatformWeb).translations?.web)
    .map((p) => (p as PlatformWeb).translations.web);
}

export function registerServerCommands(
  platformId: string,
  version: string,
  loader: (commandsAPI: any) => CommandPack<CommandDefinitionServer>,
) {
  return registerCommands<CommandsServerAPI, CommandDefinitionServer>(platformId, version, loader);
}

export function registerWebCommands(
  platformId: string,
  version: string,
  loader: (commandsAPI: any) => CommandPack<CommandDefinitionWeb>,
  translations?: any,
) {
  return registerCommands<CommandsWebAPI, CommandDefinitionWeb>(
    platformId,
    version,
    loader,
    translations,
  );
}

export function overrideRunnerCommands(
  platformId: string,
  version: string,
  loader: (commandsAPI: any) => CommandPack<CommandDefinitionRunner<any> | CommandDefinitionWeb>,
) {
  console.log(`Override commands fields for platform '${platformId}' in version '${version}'`);

  const commandsModule = loader(getEAInternal().commands.api as any);

  Object.keys(commandsModule).forEach((key) => {
    if (!getEAInternal().commands.packs[DEFAULT_PLATFORM_ID]![key]!) {
      return;
    }

    if (!getEAInternal().commands.packs[platformId]) {
      getEAInternal().commands.packs[platformId] = {};
    }

    getEAInternal().commands.packs[platformId]![key]! = {
      ...getEAInternal().commands.packs[DEFAULT_PLATFORM_ID]![key]!,
      ...commandsModule[key],
      platformId,
    };
  });
}

export function registerRunnerCommands(
  platformId: string,
  version: string,
  loader: <K extends CommandDefinitionBaseType = CommandDefinitionBaseType>(
    commandsAPI: any,
    // @ts-ignore
  ) => CommandPack<CommandDefinitionRunner<K>>,
) {
  return registerCommands<CommandsRunnerAPI, CommandDefinitionRunner>(platformId, version, loader);
}

export function getCommand<T extends CommandDefinitionBase>(id: string, commandId: string) {
  const commands = getCommandsPack<T>(id);
  return commands[commandId];
}

initDefaults();
