"use client";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Dayjs } from "dayjs";
import React from "react";

export type DateRange = {
  start: Dayjs | null;
  end: Dayjs | null;
};

export type DateRangePickerProps = {
  value: DateRange;
  onChange: (range: DateRange) => void;
  withTime?: boolean;
  startLabel?: string;
  endLabel?: string;
  readOnly?: boolean;
  disabled?: boolean;
  startSize?: "small" | "medium";
  endSize?: "small" | "medium";
  startName?: string;
  endName?: string;
  name?: string;
  helperText?: string | { message: string };
  error?: boolean;
};

export const DateRangePicker = React.forwardRef(
  (
    {
      value,
      onChange,
      withTime = false,
      startLabel = "Start Date",
      endLabel = "End Date",
      disabled = false,
      startSize = "small",
      endSize = "small",
      startName,
      endName,
      helperText,
      readOnly,
      error,
    }: DateRangePickerProps,
    ref,
  ) => {
    const handleStartDateChange = (date: Dayjs | null) => {
      if (!date || (value.end && date.isAfter(value.end))) {
        onChange({ start: date, end: null });
      } else {
        onChange({ ...value, start: date });
      }
    };

    const handleEndDateChange = (date: Dayjs | null) => {
      if (date && value.start && date.isBefore(value.start)) {
        onChange({ ...value, end: date });
      } else {
        onChange({ ...value, end: date });
      }
    };

    const PickerComponent = withTime ? DateTimePicker : DatePicker;

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div style={{ display: "flex", gap: "16px" }}>
          <PickerComponent
            label={startLabel}
            value={value.start}
            onChange={handleStartDateChange}
            disabled={disabled}
            readOnly={readOnly}
            slotProps={{
              textField: {
                size: startSize,
                name: startName,
                error: error,
                helperText: typeof helperText === "string" ? helperText : helperText?.message,
              },
            }}
          />
          <PickerComponent
            label={endLabel}
            value={value.end}
            onChange={handleEndDateChange}
            readOnly={readOnly}
            minDate={value.start!}
            disabled={!value.start}
            slotProps={{
              textField: {
                size: endSize,
                name: endName,
                error: error,
              },
            }}
          />
        </div>
      </LocalizationProvider>
    );
  },
);

DateRangePicker.displayName = "DateRangePicker";
