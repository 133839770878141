import {
  authenticateVirtualUser,
  countVirtualUser,
  createVirtualUser,
  createVirtualUserPool,
  deleteVirtualUser,
  getVirtualUser,
  getVirtualUserPool,
  updateVirtualUser,
  updateVirtualUserPool,
} from "../../api/endpoints";
import { createMutation, createQuery } from "./apiHooks";

export const VIRTUAL_USERS_QUERY_KEY = ["VirtualUser", "Get"];

export const useGetVirtualUsers = createQuery(getVirtualUser, {
  queryKey: VIRTUAL_USERS_QUERY_KEY,
});

export const useCountVirtualUsers = createQuery(countVirtualUser, {
  queryKey: ["VirtualUser", "Get"],
});

export const useCreateVirtualUser = createMutation(createVirtualUser, {
  mutationKey: ["VirtualUser", "Create"],
});

export const useUpdateVirtualUser = createMutation(updateVirtualUser, {
  mutationKey: ["VirtualUser", "Update"],
});

export const useDeleteVirtualUser = createMutation(deleteVirtualUser, {
  mutationKey: ["VirtualUser", "Delete"],
});

export const useAuthenticateVirtualUser = createMutation(authenticateVirtualUser, {
  mutationKey: ["VirtualUser", "Authenticate"],
});

export const useGetVirtualUserPool = createQuery(getVirtualUserPool, {
  queryKey: ["VirtualUserPool", "Get"],
});

export const useCreateVirtualUserPool = createMutation(createVirtualUserPool, {
  mutationKey: ["VirtualUserPool", "Create"],
});

export const useUpdateVirtualUserPool = createMutation(updateVirtualUserPool, {
  mutationKey: ["VirtualUserPool", "Update"],
});
