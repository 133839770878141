"use client";
import { IconMenuItem, MenuItemData, NestedMenuItem } from "mui-nested-menu";
import React from "react";

export type ExtendedMenuItemData<T = string> = MenuItemData & { value?: T };

export interface nestedMenuItemsFromObjectProps {
  menuItemsData: ExtendedMenuItemData[];
  isOpen: boolean;
  handleClose: () => void;
  handleClick: (value: string) => void;
}

/**
 * Create a JSX element with nested elements creating a nested menu.
 * Every menu item should have a uid provided
 */
export function nestedMenuItemsFromObject(
  { menuItemsData: items, isOpen, handleClose, handleClick }: nestedMenuItemsFromObjectProps,
  parentKey = "",
) {
  return items.map((item, index) => {
    const { leftIcon, rightIcon, label, value, items: nestedItems, callback, sx, disabled } = item;
    const key = `${parentKey}-${label}-${index}`;

    const sxStyles = {
      minWidth: "14rem",
      "&:hover": {
        backgroundColor: "secondary.50",
      },
    };

    if (nestedItems && nestedItems.length > 0) {
      return (
        <NestedMenuItem
          key={key}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          label={label}
          parentMenuOpen={isOpen}
          // eslint-disable-next-line -- hot spot for typechecking, using any to improve type speeds
          sx={{ ...sxStyles, ...sx } as any}
          disabled={disabled}
        >
          {nestedMenuItemsFromObject(
            {
              handleClose,
              isOpen,
              menuItemsData: nestedItems,
              handleClick,
            },
            key,
          )}
        </NestedMenuItem>
      );
    } else {
      return (
        <IconMenuItem
          key={key}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          label={label}
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            handleClose();
            callback && callback(event, item);
            handleClick(value!);
          }}
          sx={{ ...sxStyles, ...sx }}
          disabled={disabled}
        />
      );
    }
  });
}
