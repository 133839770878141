"use client";

import { EA_UTILS } from "@ea/shared_types/next/ea.utils";
import { SxProps } from "@mui/material";
import { useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ExtendedMenuItemData, NestedSelect } from "../materialUI";
import { EditableContext } from "./utils";

type FormNestedSelectProps<T = string> = {
  name: string;
  label: string;
  dataTestid: string;
  menuItems: ExtendedMenuItemData[];
  fullWidth?: boolean;
  sx?: SxProps;
  required?: boolean;
  onChange?: (v: T | string) => void;
  loading?: boolean;
};

export const FormNestedSelect = <T,>({
  name,
  label,
  dataTestid,
  menuItems,
  fullWidth = true,
  required = true,
  sx,
  onChange,
  loading,
}: FormNestedSelectProps<T>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const editable = useContext(EditableContext);

  const fieldError = EA_UTILS.object.getNestedValue(errors, name);

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      defaultValue=""
      render={({ field }) => {
        return (
          <NestedSelect
            label={label}
            loading={loading}
            menuItems={menuItems}
            selectedValue={field.value}
            onChange={(value) => {
              onChange ? onChange(value) : field.onChange(value);
            }}
            textFieldProps={{
              fullWidth,
              error: !!fieldError,
              required,
              InputProps: { readOnly: !editable },
              classes: { root: editable ? "" : "MuiReadOnly" },
            }}
            data-testid={dataTestid}
            sx={sx}
          />
        );
      }}
    />
  );
};
