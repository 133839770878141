export const convertToFormData = (data: object, prefix?: string) => {
  const keys = Object.keys(data);
  return keys.reduce((acc, key) => {
    const name = prefix ? `${prefix}[${key}]` : key;
    if (data[key] && typeof data[key] === "object") {
      const result = convertToFormData(data[key], name);
      Object.keys(result).forEach((resKey) => {
        acc[resKey] = result[resKey];
      });
    } else {
      acc[name] = data[key];
    }
    return acc;
  }, {});
};
