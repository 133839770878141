import { Box, BoxProps } from "@mui/material";

export interface ActionButtonsWrapperProps extends BoxProps {
  direction?: "row" | "column";
  variant?: "default" | "sticky";
  justify?: "left" | "right" | "center" | "space-around" | "space-between";
  width?: string;
  flat?: boolean;
}

export const ActionButtonsWrapper = ({
  children,
  direction = "column",
  variant = "default",
  justify,
  width = "100%",
  flat = false,
  sx,
  ...props
}: ActionButtonsWrapperProps) => (
  <Box
    sx={{
      padding: flat ? 0 : "1rem 1.5rem",
      display: "flex",
      alignItems: "center",
      width,
      gap: "1.5rem",
      justifyContent: justify || "center",
      flexDirection: direction,
      ...(variant === "sticky"
        ? {
            position: "absolute",
            bottom: 0,
            left: 0,
          }
        : {}),
      ...sx,
    }}
    {...props}
  >
    {children}
  </Box>
);
