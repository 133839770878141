import { OptionType } from "@ea/shared_types/next/ea.types";
import { Box, Chip } from "../materialUI";

interface OptionWithChipType {
  option: OptionType;
  chipLabel: string;
}

export const OptionWithChip = ({ option, chipLabel, ...props }: OptionWithChipType) => {
  return (
    <Box
      component="li"
      sx={(theme) => ({
        borderBottom: option?.isDefault ? `1px solid ${theme.palette.neutral[100]}` : "",
      })}
      {...props}
      key={`${option.value}`}
    >
      {option.label}
      {option.isDefault && (
        <Chip
          key={`${option.value}-chip`}
          size="small"
          label={chipLabel}
          variant="outlined"
          sx={{ ml: 2 }}
        />
      )}
    </Box>
  );
};
