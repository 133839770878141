import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";

type DrawerHeaderProps = {
  leftPocket?: ReactNode;
  rightPocket?: ReactNode;
  withCloseIcon?: boolean;
  onClose?: () => void;
  sx?: SxProps<Theme>;
};

export const DrawerHeader = ({
  leftPocket,
  rightPocket,
  withCloseIcon,
  onClose,
  sx,
}: DrawerHeaderProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "1rem 1.5rem 0 1.5rem",
        ...sx,
      }}
    >
      {leftPocket}
      <Box>
        {rightPocket}
        {withCloseIcon && (
          <IconButton onClick={onClose}>
            <CloseIcon color="secondary" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
