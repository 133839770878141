"use client";
import React from "react";
import { useOverlayStore } from "./useOverlayStore";

export const OverlayManager = () => {
  const { overlays } = useOverlayStore();

  return (
    <>
      {overlays.map(({ id, component, props, isOpen }) => (
        <React.Fragment key={id}>
          {React.createElement(component, {
            ...(props as object),
            isOpen,
          } as React.Attributes)}
        </React.Fragment>
      ))}
    </>
  );
};
