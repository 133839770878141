import {
  createNumberSequence,
  getNumberSequences,
  updateNumberSequence,
} from "../../api/endpoints";
import { createMutation, createQuery } from "./apiHooks";

export const useGetNumberSequences = createQuery(getNumberSequences, {
  queryKey: ["NumberSequence", "Get"],
});

export const useCreateNumberSequences = createMutation(createNumberSequence, {
  mutationKey: ["NumberSequence", "Create"],
});

export const useUpdateNumberSequences = createMutation(updateNumberSequence, {
  mutationKey: ["NumberSequence", "Update"],
});
