import { Badge, BadgeProps, Chip, ChipProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getNestedThemeColor } from "../../../utils/getNestedThemeColor";
interface StyledBadgeProps extends BadgeProps {
  customColor: string;
  isOutlined?: boolean;
}

const BaseStyledBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== "customColor",
})<StyledBadgeProps>(({ theme, customColor, isOutlined, badgeContent }) => {
  const nestedColor = getNestedThemeColor(theme, customColor);
  const isIcon = typeof badgeContent !== "string";
  return {
    "& .MuiBadge-badge": {
      backgroundColor: isOutlined || isIcon ? "transparent" : nestedColor,
      color: isOutlined || isIcon ? nestedColor : "white",
      whiteSpace: "nowrap",
      border: isOutlined ? `1px solid ${nestedColor}` : "none",
    },
  };
});

export const StyledBadge: React.FC<StyledBadgeProps> = (props) => {
  return <BaseStyledBadge {...props} />;
};

interface StyledChipProps extends ChipProps {
  customColor: string;
}

const BaseStyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "customColor",
})<StyledChipProps>(({ theme, customColor }) => {
  const nestedColor = getNestedThemeColor(theme, customColor);

  return {
    "&.MuiChip-filled": {
      backgroundColor: nestedColor,
      color: "white",
      whiteSpace: "nowrap",
    },
    "&.MuiChip-outlined": {
      backgroundColor: "transparent",
      color: nestedColor,
      whiteSpace: "nowrap",
      border: `1px solid ${nestedColor}`,
    },
  };
});

export const StyledChip: React.FC<StyledChipProps> = (props) => {
  return <BaseStyledChip {...props} />;
};
