"use client";
import { CodeType } from "@ea/shared_types/next/ea.enums";
import { Editor, EditorProps, MonacoDiffEditor, OnMount } from "@monaco-editor/react";
import { constrainedEditor } from "constrained-editor-plugin";
import { useCallback, useEffect, useRef } from "react";
import { Box, Theme } from "../materialUI";
import { useExtraLibs, useResizable } from "./hooks";

export interface CodeEditorProps {
  defaultLanguage?: string;
  onMount?: OnMount;
  monacoEditorProps?: EditorProps;
  initialHeight?: number;
  onChange?: (value: string) => void;
  scriptId?: number;
  readOnly?: boolean;
  resizable?: boolean;
  mode?: CodeType.ADVANCED | CodeType.EXPRESSION | CodeType.CODE_TEMPLATE;
  value?: string;
  error?: boolean;
}

export const CodeEditor = ({
  defaultLanguage = "javascript",
  onMount,
  monacoEditorProps,
  initialHeight = 300,
  onChange,
  scriptId,
  readOnly = false,
  resizable = true,
  error = false,
  mode = CodeType.ADVANCED,
}: CodeEditorProps) => {
  const editorRef = useRef<MonacoDiffEditor | null>(null);
  const { height, resizeHandleRef, handleMouseDown } = useResizable(initialHeight);

  useExtraLibs(scriptId);

  const handleEditorDidMount: OnMount = useCallback(
    (editor, monaco) => {
      // TODO: Fix any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      editorRef.current = editor as any;
      const initialValue = monacoEditorProps?.value || "";

      editor.setValue(initialValue);

      if (mode === CodeType.EXPRESSION) {
        const constrainedInstance = constrainedEditor(monaco);
        const model = editor.getModel();
        constrainedInstance.initializeIn(editor);

        const restrictions = [
          { range: [1, 1, 1, initialValue.length + 1], allowMultiline: false }, // Restrict the first line
        ];

        if (model) {
          constrainedInstance.addRestrictionsTo(model, restrictions);
        }
      }

      if (onMount) {
        onMount(editor, monaco);
      }

      monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
        noLib: true,
        allowNonTsExtensions: true,
        target: monaco.languages.typescript.ScriptTarget.ES2020,
        moduleResolution: monaco.languages.typescript.ModuleResolutionKind.NodeJs,
      });
    },
    [onMount, mode, monacoEditorProps?.value],
  );

  const handlEeditorValueChange = (editorValue: string | undefined) => {
    if (onChange) {
      onChange(editorValue || "");
    }
  };

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.layout();
    }
  }, [height]);

  const borderColor = (theme: Theme) =>
    error
      ? theme.palette.error[500]
      : readOnly
        ? theme.palette.neutral[200]
        : theme.palette.secondary.main;

  return (
    <Box
      sx={(theme) => ({
        position: "relative",
        border: `1px solid ${borderColor(theme)}`,
        borderBottom: `${resizable ? "none" : ""}`,
        borderRadius: "8px",
        width: "100%",
      })}
    >
      <Editor
        height={height}
        defaultLanguage={defaultLanguage}
        onMount={handleEditorDidMount}
        onChange={handlEeditorValueChange}
        options={{
          minimap: { enabled: false },
          scrollBeyondLastLine: false,
          readOnly: readOnly,
        }}
        {...monacoEditorProps}
      />
      {resizable && (
        <Box
          component="div"
          ref={resizeHandleRef}
          sx={(theme) => ({
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: "8px",
            borderRadius: "4px",
            background: theme.palette.neutral[300],
            cursor: "row-resize",
          })}
          onMouseDown={handleMouseDown}
        />
      )}
    </Box>
  );
};
