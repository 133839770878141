import {
  cloneScript,
  closeScript,
  createScript,
  downloadScriptsDocumentation,
  editScript,
  exportScript,
  getOneScript,
  getScripts,
  getScriptsCount,
  importScript,
  scriptAutocomplete,
} from "../../api/endpoints";
import { createMutation, createQuery } from "./apiHooks";

export const SCRIPTS_QUERY_KEY = ["Scripts", "Get"];

export const useGetScripts = createQuery(getScripts, {
  queryKey: SCRIPTS_QUERY_KEY,
});
export const useGetOneScript = createQuery(getOneScript, {
  queryKey: ["Scripts", "GetOne"],
});
export const useCountScripts = createQuery(getScriptsCount, {
  queryKey: ["Scripts", "Count"],
});
export const useCreateScript = createMutation(createScript, {
  mutationKey: ["Scripts", "Create"],
});
export const useScriptsAutocomplete = createQuery(scriptAutocomplete, {
  queryKey: ["Scripts", "Autocomplete"],
});
export const useCloseScripts = createMutation(closeScript, {
  mutationKey: ["Scripts", "CloseScripts"],
});

export const useCloneScripts = createMutation(cloneScript, {
  mutationKey: ["Scripts", "CloneScripts"],
});

export const useDownloadScripts = createMutation(exportScript, {
  mutationKey: ["Scripts", "DownloadScripts"],
});

export const useImportScripts = createMutation(importScript, {
  mutationKey: ["Scripts", "ImportScripts"],
});

export const useEditScripts = createMutation(editScript, {
  mutationKey: ["Scripts", "UpdateScripts"],
});

export const useDownloadScriptsDocumentation = createMutation(downloadScriptsDocumentation, {
  mutationKey: ["Scripts", "DownloadScriptsDocumentation"],
});
