"use client";
import { getTranslations } from "@ea/runner_loader/commands.loader";
import { SupportedLanguages } from "@ea/shared_types/types";
import merge from "lodash/merge";
import { useMemo } from "react";
import { useTranslationContext } from "./TranslationContext";
import { AllTranslationKeys, TranslateFunctionWithNamespace, TranslationFunction } from "./types";

export const usePlatformsTranslations = () => {
  const translations = useMemo(
    () =>
      getTranslations().reduce(
        (acc, t) => ({
          [SupportedLanguages.EN]: merge(acc[SupportedLanguages.EN] || {}, t?.en),
          [SupportedLanguages.PL]: merge(acc[SupportedLanguages.PL] || {}, t.pl || t.en),
        }),
        {},
      ),
    [],
  );

  return { translations };
};

export const useTranslations = <TNamespace extends AllTranslationKeys = "">(
  namespace?: TNamespace,
): { t: TranslationFunction<TNamespace>; locale: string } => {
  const { tWithNamespace, locale } = useTranslationContext();
  return {
    t: (tWithNamespace as unknown as TranslateFunctionWithNamespace<TNamespace>)(namespace),
    locale,
  };
};
