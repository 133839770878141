import { Drawer as MUIDrawer, DrawerProps as MUIDrawerProps } from "@mui/material";
import React from "react";
import { PageLoader } from "../../../PageLoader";
import { DrawerContent, DrawerContentProps } from "./DrawerContent";
import { DrawerContext } from "./DrawerContext";
import { DrawerHeader } from "./DrawerHeader";

export type SIZE = "sm" | "lg";

const PADDING_CONFIG = {
  sm: "1.5rem",
  lg: "4.44rem",
};

export const getPaddingValue = (size?: SIZE, py: string = "0") =>
  `${py} ${size ? PADDING_CONFIG[size] : "0"}`;

interface DrawerProps extends MUIDrawerProps {
  columns?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  header?: React.ReactNode | string;
  isLoading?: boolean;
  withCloseIcon?: boolean;
  contentProps?: DrawerContentProps;
}

const Drawer = ({
  columns = 4,
  anchor = "right",
  withCloseIcon = true,
  isLoading,
  header,
  contentProps,
  ...props
}: DrawerProps) => {
  const validColumns = Math.min(columns, 12);
  const drawerWidthPercent = `${(validColumns / 12) * 100}%`;

  const defaultStyles = {
    width: drawerWidthPercent,
    zIndex: "auto",
  };

  const mergedStyles = {
    ...defaultStyles,
    ...props.PaperProps?.style,
  };

  return (
    <DrawerContext.Provider value={true}>
      <MUIDrawer
        {...props}
        anchor={anchor}
        PaperProps={{
          ...props.PaperProps,
          style: mergedStyles,
        }}
        sx={{ ...props.sx, zIndex: 15 }}
      >
        {header && (
          <DrawerHeader
            withCloseIcon={withCloseIcon}
            leftPocket={header}
            onClose={() => props.onClose?.({}, "backdropClick")}
          />
        )}

        {isLoading ? (
          <PageLoader />
        ) : (
          <DrawerContent {...contentProps}>{props.children}</DrawerContent>
        )}
      </MUIDrawer>
    </DrawerContext.Provider>
  );
};
export { Drawer, DrawerContent, DrawerHeader };
