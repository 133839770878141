"use client";

import { OptionType } from "@ea/shared_types/next/ea.types";
import { EA_UTILS } from "@ea/shared_types/next/ea.utils";
import { ReactNode, useContext } from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { useTranslations } from "../../translations/useTranslations";
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteValue,
  TextField,
  TextFieldProps,
} from "../materialUI";
import { OptionWithChip } from "../OptionWithChip";
import { EditableContext } from "./utils";

interface FormAutocompleteProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends Omit<
    AutocompleteProps<OptionType, Multiple, DisableClearable, FreeSolo>,
    "renderInput" | "name"
  > {
  name: string;
  label?: string | null;
  dataTestid: string;
  options: OptionType[];
  placeholder?: string;
  textFieldProps?: TextFieldProps;
  required?: boolean;
  renderOptions?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: OptionType,
  ) => React.ReactNode;
  showHelperText?: boolean;
  helperText?: ReactNode;
  clearableName?: string;
  additionalOnChange?: (
    option?: AutocompleteValue<OptionType, Multiple, DisableClearable, FreeSolo>,
  ) => void;
}

export const FormAutocomplete = <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  name,
  dataTestid,
  options,
  label,
  placeholder,
  defaultValue,
  freeSolo,
  textFieldProps,
  required,
  renderOptions,
  showHelperText = true,
  helperText,
  onChange,
  clearableName,
  additionalOnChange,
  ...props
}: FormAutocompleteProps<Multiple, DisableClearable, FreeSolo>) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslations("Scripts");

  const containsDefaultOption = options.some((o) => o.isDefault);

  const optionRenderer = renderOptions
    ? renderOptions
    : containsDefaultOption
      ? (props, option: OptionType) => (
          <OptionWithChip
            option={option}
            chipLabel={t("drawer.default")}
            {...props}
            key={`${option.value}`}
          />
        )
      : (props, option: OptionType) => {
          const { key, ...rest } = props;
          return (
            <li
              key={`${key}${option.value}`}
              {...rest}
              onClick={(e) => {
                e.stopPropagation();
                props.onClick(e);
              }}
            >
              {option.label}
            </li>
          );
        };

  const editable = useContext(EditableContext);

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field }) => {
        const value: typeof field.value | null = props.multiple
          ? Array.isArray(field.value)
            ? options.filter((option) => field.value.includes(option.value))
            : []
          : options.find((option) => option.value === field.value) || null;

        const getRequiredValue = () => {
          if (!required) {
            return false;
          }
          //bug in mui. If field is multiple and has values inside just turn off required
          if (required && props.multiple) {
            return value.length > 0 ? false : true;
          }
          return required;
        };

        return (
          <Autocomplete
            {...field}
            options={options}
            data-testid={dataTestid}
            getOptionLabel={(option) => option.label}
            value={value}
            fullWidth
            freeSolo={freeSolo}
            readOnly={!editable}
            disabled={!editable}
            classes={{
              root: editable ? "" : "MuiReadOnly",
            }}
            renderOption={optionRenderer}
            getOptionKey={(option: OptionType) => `${option.value}`}
            onChange={
              onChange ||
              ((_, selectedOption) => {
                if (!selectedOption && clearableName) {
                  setValue(clearableName, null);
                  return;
                }
                if (props.multiple && Array.isArray(selectedOption)) {
                  field.onChange(selectedOption.map((option) => option.value));
                } else if (!props.multiple && selectedOption) {
                  field.onChange(selectedOption.value);
                } else {
                  field.onChange(selectedOption);
                }
                additionalOnChange?.(selectedOption);
              })
            }
            isOptionEqualToValue={(option, value) => {
              return option.value === value.value;
            }}
            renderInput={(params) => {
              const fieldError = EA_UTILS.object.getNestedValue(errors, name) as
                | FieldError
                | undefined;

              const finalHelperText =
                showHelperText && helperText
                  ? helperText
                  : showHelperText && fieldError
                    ? fieldError.message
                    : "";

              return (
                <TextField
                  {...params}
                  size="small"
                  error={!!fieldError}
                  label={label}
                  focused={false}
                  helperText={finalHelperText}
                  required={getRequiredValue()}
                  {...textFieldProps}
                />
              );
            }}
            {...props}
          />
        );
      }}
    />
  );
};
