"use client";
import { EA_UTILS } from "@ea/shared_types/next/ea.utils";
import { ReactNode, useContext } from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import {
  FormControlProps,
  FormHelperText,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  ToggleButtonProps,
} from "../materialUI";
import { EditableContext } from "./utils";

type FormToggleButtonGroupProps = {
  name: string;
  options: { label: ReactNode; value: string | number | boolean }[];
  dataTestId: string;
  formControlProps?: FormControlProps;
  toggleButtonGroupProps?: ToggleButtonGroupProps;
  toggleButtonProps?: Partial<ToggleButtonProps>;
};

export const FormToggleButtonGroup = ({
  name,
  options,
  dataTestId,
  toggleButtonGroupProps,
  toggleButtonProps,
}: FormToggleButtonGroupProps) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const editable = useContext(EditableContext);

  const fieldError = EA_UTILS.object.getNestedValue(errors, name) as FieldError | undefined;
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }) => (
        <ToggleButtonGroup
          data-testid={dataTestId}
          onBlur={onBlur}
          value={value}
          onChange={onChange}
          {...toggleButtonGroupProps}
        >
          {options.map(({ value, label }, index) => (
            <ToggleButton
              key={`${value}`}
              value={value}
              data-testid={`${dataTestId}-${value}`}
              disabled={!editable}
              {...toggleButtonProps}
            >
              {label}
            </ToggleButton>
          ))}
          {fieldError && (
            <FormHelperText data-testid={`${dataTestId}-helper-text`} error>
              {fieldError.message}
            </FormHelperText>
          )}
        </ToggleButtonGroup>
      )}
    />
  );
};
