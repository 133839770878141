import {
  deleteScreenshotsManually,
  getGlobalSettings,
  getScreenshotsAutoCleanup,
  setScreenshotsAutoCleanup,
  updateGlobalSettings,
} from "../../api/endpoints";
import { createMutation, createQuery } from "./apiHooks";

export const useGetGlobalSettings = createQuery(getGlobalSettings, {
  queryKey: ["GlobalSettings", "Get"],
});

export const useUpdateGlobalSettings = createMutation(updateGlobalSettings, {
  mutationKey: ["GlobalSettings", "Update"],
});

export const useGetScreenshotsAutoCleanup = createQuery(getScreenshotsAutoCleanup, {
  queryKey: ["InternalSettings", "Get"],
});

export const useSetScreenshotsAutoCleanup = createMutation(setScreenshotsAutoCleanup, {
  mutationKey: ["InternalSettings", "Set"],
});

export const useDeleteScreenshotsManually = createMutation(deleteScreenshotsManually, {
  mutationKey: ["Screenshots", "Delete"],
});
