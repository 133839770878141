export type ApiContext = {
  baseUrl: string;
  getAuthToken: () => Promise<string | undefined>;
  signOut: () => Promise<void>;
};

export const getApiContext = (): ApiContext => {
  if (!globalThis.EA_API_CONTEXT) throw new Error("EA_API_CONTEXT not set");

  return globalThis.EA_API_CONTEXT;
};

export const setApiContext = (apiContext: ApiContext) => {
  globalThis.EA_API_CONTEXT = apiContext;
};
