import { EA_INTERNAL } from "./ea.internal.types";

export type GlobalType = typeof globalThis &
  (Window | NodeJS.Global) & {
    EA_INTERNAL: EA_INTERNAL;
    // WORK: add types
    EA_EXTENSION_BACKGROUND: any;
    EA_EXTENSION: any; // WORK add types
  };

/**
 * Returns the global object (window in browsers, global in Node.js)
 * with the correct type information.
 *
 * @returns The global object with correct typing
 */
export function getGlobalObject(): GlobalType {
  if (typeof window !== "undefined") {
    return window as GlobalType;
  }
  if (typeof global !== "undefined") {
    return global as GlobalType;
  }
  if (typeof self !== "undefined") {
    return self as GlobalType;
  }
  throw new Error("Unable to locate global object");
}

export function getEAInternal(): EA_INTERNAL {
  return getGlobalObject().EA_INTERNAL;
}

export function platformSorter(a: string, b: string) {
  const aHasDefault = a.toLowerCase().includes("default");
  const bHasDefault = b.toLowerCase().includes("default");

  if (aHasDefault && !bHasDefault) {
    return -1;
  } else if (!aHasDefault && bHasDefault) {
    return 1;
  } else {
    return a.localeCompare(b);
  }
}
