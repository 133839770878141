import { replaceSchedulerGroups } from "../../api/endpoints/schedulerGantt";
import { createMutation } from "./apiHooks";
import { SCHEDULER_QUERY_KEY } from "./useScheduler";

export const useReplaceSchedulerGroups = createMutation(
  replaceSchedulerGroups,
  {
    mutationKey: ["SchedulersGantt", "ReplaceGroups"],
  },
  SCHEDULER_QUERY_KEY,
);
