"use client";
import { EA_UTILS } from "@ea/shared_types/next/ea.utils";
import { useContext } from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import {
  AdapterDayjs,
  LocalizationProvider,
  TextFieldVariants,
  TimePicker,
  TimePickerProps,
} from "../materialUI";
import { EditableContext } from "./utils";

type FormTimePickerProps = TimePickerProps<Date> & {
  name: string;
  dataTestid: string;
  size?: "small" | "medium";
  fullWidth?: boolean;
  label?: string;
  placeholder?: string;
  views?: string[];
  ampm?: boolean;
  variant?: TextFieldVariants;
};

export const FormTimePicker = ({
  name,
  dataTestid,
  size = "small",
  fullWidth,
  label,
  placeholder,
  format = "HH:mm",
  views = ["hours", "minutes"],
  ampm = false,
  variant = "outlined",
  ...props
}: FormTimePickerProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const fieldError = EA_UTILS.object.getNestedValue(errors, name) as FieldError | undefined;
  const editable = useContext(EditableContext);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TimePicker
            ampm={ampm}
            timezone="system"
            readOnly={!editable}
            value={value}
            views={views}
            onChange={(newValue) => {
              onChange(newValue);
            }}
            slotProps={{
              textField: {
                placeholder: placeholder,
                label: label,
                fullWidth: fullWidth,
                variant: variant,
                size: size,
                error: !!fieldError,
                helperText: fieldError?.message || "",
                inputProps: { "data-testid": dataTestid },
              },
            }}
            {...props}
          />
        )}
      />
    </LocalizationProvider>
  );
};
