"use client";
import { createContext } from "react";
/**
 * Submits a form using its ID.
 * Useful when submitting a form in a out of context modal
 * @param formId The ID of the form to be submitted.
 */

export function submitFormById(formId: string): void {
  const form = document.getElementById(formId);
  if (form) {
    form.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  } else {
    console.error(`Form with ID ${formId} not found.`);
  }
}

export const EditableContext = createContext(true);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const trimStringValues = <TFormValues extends Record<string, any>>(
  data: TFormValues,
): TFormValues => {
  const trimmedData = {} as TFormValues;
  for (const key in data) {
    if (typeof data[key] === "string") {
      trimmedData[key] = data[key].trim();
    } else if (Array.isArray(data[key])) {
      trimmedData[key] = data[key].map((item) => {
        if (typeof item === "string") {
          return item.trim();
        } else if (typeof item === "object" && item !== null) {
          return trimStringValues(item);
        } else {
          return item;
        }
      });
    } else if (typeof data[key] === "object" && data[key] !== null) {
      trimmedData[key] = trimStringValues(data[key]);
    } else {
      trimmedData[key] = data[key];
    }
  }
  return trimmedData;
};
