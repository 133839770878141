"use client";

import { styled } from "@mui/material";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { DataTestIds } from "../../dataTestIds";
import { useNotification } from "../../hooks/useNotification";
import { FileUploadIcon } from "../../icons";
import { useTranslations } from "../../translations/useTranslations";
import { Action } from "../ActionsBar";
import { Paper, Typography } from "../materialUI";

const StyledPaper = styled(Paper)(
  ({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  border-radius: 8px;
  background-color: ${theme.palette.neutral[50]};
  cursor: pointer;
  margin: 0 auto;
`,
);

type DropzoneProps = {
  onFileSelect: (file: File) => void | Promise<void>;
  browseFileButton?: string;
  dropzoneTitle?: string;
  dropzoneOrText?: string;
  invalidFileMessage?: string;
  accept?: Record<string, string[]>;
  clear?: boolean;
  disabled?: boolean;
};

export const FileDropzone = ({
  onFileSelect,
  browseFileButton,
  dropzoneTitle,
  dropzoneOrText,
  invalidFileMessage,
  accept,
  clear = false,
  disabled = false,
}: DropzoneProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [prevClear, setPrevClear] = useState(clear);
  const { toast } = useNotification();
  const { t } = useTranslations("fileDropzone");

  const isOrEmpty = dropzoneOrText === "";

  if (clear !== prevClear) {
    setPrevClear(clear);
    setSelectedFile(null);
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      if (!file) {
        toast.error(invalidFileMessage || t("invalidFile"));
        return;
      }

      setSelectedFile(file);
      await onFileSelect(file);
    },
    multiple: false,
    accept,
  });

  return (
    <StyledPaper variant="outlined" {...getRootProps()}>
      <input data-testid={DataTestIds.DROPZONE} {...getInputProps()} />
      {selectedFile ? (
        <Typography variant="subtitle1" sx={{ mb: 2, wordWrap: "break-word", maxWidth: "100%" }}>
          {selectedFile.name}
        </Typography>
      ) : (
        <>
          <FileUploadIcon
            sx={(theme) => ({
              width: "32px",
              height: "32px",
              mb: 2,
              color: theme.palette.neutral.main,
            })}
          />
          <Typography
            sx={{ color: (theme) => theme.palette.neutral[600], mb: 2 }}
            fontWeight={500}
            variant="body1"
          >
            {dropzoneTitle || t("title")}
          </Typography>
          {!isOrEmpty && (
            <Typography
              sx={{ color: (theme) => theme.palette.neutral[600], mb: 2 }}
              fontWeight={500}
              variant="body1"
            >
              {dropzoneOrText || t("or")}
            </Typography>
          )}
        </>
      )}
      <Action variant="outlined" disabled={disabled}>
        {browseFileButton || t("browseFile")}
      </Action>
    </StyledPaper>
  );
};
