import { Stack, StackProps } from "@mui/material";

export const FormInputsWrapper = ({
  children,
  justifyContent = "flex-start",
  spacing = 2,
  direction = "column",
  alignItems = direction === "row" ? "center" : "flex-start",
  width = "100%",
  ...props
}: StackProps) => {
  return (
    <Stack
      justifyContent={justifyContent}
      direction={direction}
      alignItems={alignItems}
      spacing={spacing}
      width={width}
      {...props}
    >
      {children}
    </Stack>
  );
};
