"use client";
import { StyledComponent } from "@emotion/styled";
import { Pagination, PaginationProps } from "@mui/material";
import { Theme, styled } from "@mui/material/styles";

export const StyledPagination: StyledComponent<
  PaginationProps & { theme?: Theme },
  object,
  object
> = styled(Pagination)`
  font-style: italic;
  margin-left: auto;
`;
