import {
  createKpi,
  getExecutionJobStatistics,
  getExecutionStatistics,
  getKpis,
  updateKpi,
} from "../../api/endpoints";
import { createMutation, createQuery } from "./apiHooks";

export const useGetKpis = createQuery(getKpis, {
  queryKey: ["Kpis", "Get"],
});

export const useCreateKpi = createMutation(createKpi, {
  mutationKey: ["Kpis", "Create"],
});

export const useUpdateKpi = createMutation(updateKpi, {
  mutationKey: ["Kpis", "Update"],
});

export const useGetExecutionStatistics = createQuery(getExecutionStatistics, {
  queryKey: ["Kpis", "GetExecutionStatistics"],
});

export const useGetExecutionJobStatistics = createQuery(getExecutionJobStatistics, {
  queryKey: ["Kpis", "GetJobStatistics"],
});
