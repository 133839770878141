import React, { forwardRef } from "react";
import { BarWrapper } from "./ActionsBar.styles";

type ActionsBarProps = {
  children: React.ReactNode;
};

export const ActionsBar = forwardRef<HTMLDivElement, ActionsBarProps>(({ children }, ref) => {
  return <BarWrapper ref={ref}>{children}</BarWrapper>;
});
