import { getExportedCsv, getGenerateExportData } from "../../api/endpoints";
import { createMutation } from "./apiHooks";

export const useGenerateExport = createMutation(getGenerateExportData, {
  mutationKey: ["Export", "Generate"],
});

export const useGetExportedCsv = createMutation(getExportedCsv, {
  mutationKey: ["Export", "Download"],
});
