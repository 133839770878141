"use client";

import { EA_UTILS } from "@ea/shared_types/next/ea.utils";
import { useContext } from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { DateRangePicker, DateRangePickerProps } from "../materialUI";
import { EditableContext } from "./utils";

interface FormDateRangePickerProps extends Omit<DateRangePickerProps, "value" | "onChange"> {
  name: string;
}

export const FormDateRangePicker = ({
  name,
  ...dateRangePickerProps
}: FormDateRangePickerProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const fieldError = EA_UTILS.object.getNestedValue(errors, name) as FieldError | undefined;
  const editable = useContext(EditableContext);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <DateRangePicker
          {...field}
          value={field.value || { start: null, end: null }}
          onChange={(newValue) => field.onChange(newValue)}
          error={!!fieldError}
          readOnly={!editable}
          helperText={fieldError?.message}
          {...dateRangePickerProps}
        />
      )}
    />
  );
};
