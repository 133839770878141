import {
  createApiKey,
  deleteApiKey,
  getApiKeys,
  regenerateApiKey,
  updateApiKey,
} from "../../api/endpoints";
import { createMutation, createQuery } from "./apiHooks";

export const useGetApiKeys = createQuery(getApiKeys, {
  queryKey: ["Tokens", "Get"],
});

export const useCreateApiKey = createMutation(createApiKey, {
  mutationKey: ["Tokens", "Create"],
});

export const useUpdateApiKey = createMutation(updateApiKey, {
  mutationKey: ["Tokens", "Update"],
});

export const useRegenerateApiKey = createMutation(regenerateApiKey, {
  mutationKey: ["Tokens", "Regenerate"],
});

export const useDeleteApiKey = createMutation(deleteApiKey, {
  mutationKey: ["Tokens", "Delete"],
});
