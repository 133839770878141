export const DataTestIdProp = "data-testid";

export const DataTestIds = {
  getProjectTestId: (id: number, name: string) => `project-${id}-${name}`,
  getProjectCategoryTestId: (id: number, name: string) => `category-${id}-${name}`,
  getNewStepButtonTestId: (id: string) => `commandbar-button-new-step-${id}`,
  getCustomFieldTestId: (type: string, index: string | number) => `customField-${type}-${index}`,
  getTabTestId: (label: string) => `tab-${label}`,
  getOptionTestId: (text: string | number) => `option-${text}`,
  getTagTestId: (text: string | number) => `tag-${text}`,

  NAV_PROJECTS: "nav-projects",
  NAV_SCRIPTS: "nav-scripts",
  NAV_SCHEDULER: "nav-scheduler",
  NAV_DOCUMENTATION_TREE: "nav-documentation-tree",
  NAV_HISTORY: "nav-history",
  NAV_SETTINGS: "nav-settings",
  NAV_REPORTS: "nav-reports",

  SETTINGS_MENU: "settings-menu",
  SETTINGS_APPLICATION: "settings-application",
  SETTINGS_GLOBAL_SETTINGS: "settings-global-settings",
  SETTINGS_GLOBAL_SETTINGS_SCREENSHOTS: "settings-global-settings-screenshots",
  SETTINGS_SEQUENCES: "settings-sequences",
  SETTINGS_GLOBAL_VARIABLES: "settings-global-variables",
  SETTINGS_GLOBAL_EXCELS: "settings-global-excels",
  SETTINGS_SYSTEM_DEFINITIONS: "settings-system-definitions",
  SETTINGS_STORAGES: "settings-storages",
  SETTINGS_VIRTUAL_USERS: "settings-virtual-users",
  SETTINGS_API_KEY: "settings-api-key",
  SETTINGS_USER_GROUPS: "settings-user-groups",
  SETTINGS_VIRTUAL_USER_POOL: "settings-virtual-user-pool",
  SETTINGS_PROJECT_CATEGORIES: "settings-project-categories",
  SETTINGS_USERS: "settings-users",
  SETTINGS_LOCALIZATIONS: "settings-localizations",
  SETTINGS_REPORT_TEMPLATE: "settings-report-template",
  SETTINGS_ISSUE_TRACKING: "settings-issue-tracking",
  SETTINGS_ISSUE_SYSTEM_DEFINITION: "settings-issue-system-definition",
  SETTINGS_RULES: "settings-rules",
  SETTINGS_TAGS: "settings-tags",
  SETTINGS_AUDIT_TRAIL: "settings-audit-trail",
  SETTINGS_PROFILE: "settings-profile",
  SETTINGS_BASIC_SETTINGS: "settings-basic-settings",
  SETTINGS_EXECUTION_SETTINGS: "settings-execution-settings",
  SETTINGS_CODE_TEMPLATE_GROUPS: "settings-code-template-groups",
  SETTINGS_KPI: "settings-kpi",
  SETTINGS_CODE: "code",
  SETTINGS_CODE_TEMPLATES: "code-templates",

  SCRIPT_LOCAL_VARIABLES: "script-local-variables",

  CONTAINER_LICENSE_OVERVIEW: "container-license-overview",
  CONTAINER_LICENSE_ERROR: "container-license-error",
  CONTAINER_LICENSE_PPU_COUNTER: "container-license-ppu-counter",
  CONTAINER_LICENSE_STATISTICS: "container-license-statistics",

  COMMANDBAR_BUTTON_PROJECT_TREE_CREATE: "command-button-project-tree-create",
  COMMANDBAR_BUTTON_PROJECT_TREE_REMOVE: "command-button-project-tree-remove",
  COMMANDBAR_BUTTON_PROJECT_TREE_ACTIONS: "command-button-project-tree-actions",
  COMMANDBAR_BUTTON_PROJECT_TREE_ADDONS: "command-button-project-tree-addons",
  COMMANDBAR_BUTTON_PROJECT_TREE_SCHEDULE_EXECUTION:
    "command-button-project-tree-schedule-execution",
  COMMANDBAR_BUTTON_PROJECT_TREE_DISPATCHING: "command-button-project-tree-dispatching",
  COMMANDBAR_BUTTON_PROJECT_TREE_TERMINATE_EXECUTION:
    "command-button-project-tree-terminate-execution",
  COMMANDBAR_BUTTON_PROJECT_TREE_EXPORT_TO_TEST_PLAN:
    "command-button-project-tree-export-to-test-plan",

  COMMANDBAR_BUTTON_CONFIGURATIONS_FETCH: "command-button-configurations-fetch",

  MODAL_BUTTON_NO: "modal-button-no",
  MODAL_BUTTON_YES: "modal-button-yes",
  MODAL_BUTTON_DELETE: "modal-button-delete",
  MODAL_BUTTON_CANCEL: "modal-button-cancel",

  CHECKBOX_REMOVE_CONNECTED_TEST_PLAN: "checkbox-remove-connected-test-plan",
  CHECKBOX_CREATE_AS_GROUP: "checkbox-create-as-group",

  INPUT_GROUP_NAME: "input-group-name",

  SELECT_PROJECT_CATEGORY: "select-project-category",
  TREE_SEARCH: "tree-search",
  TREE_NEW_NODE: "tree-new-node",
  TREE_NEW_NODE_CELL: "tree-new-node-cell",

  TREE_NEW_PROJECT_INPUT: "tree-new-project-input",
  TREE_NEW_PROJECT_CLOSE: "tree-new-project-close",
  TREE_NEW_PROJECT_CHECK: "tree-new-project-check",

  COMMANDBAR_PROJECT_TREE: "commandbar-project-tree",
  COMMANDBAR_TAGS: "commandbar-tags",
  COMMANDBAR_SEQUENCES: "commandbar-sequences",
  COMMANDBAR_GLOBAL_VARIABLES: "commandbar-global-variables",
  COMMANDBAR_KPIS: "commandbar-kpis",
  COMMANDBAR_SCHEDULER: "commandbar-scheduler",
  COMMANDBAR_SCRIPT_CHANGELOG: "commandbar-script-changelog",
  COMMANDBAR_LOGS: "commandbar-logs",
  COMMANDBAR_AGGREGATED_JOB_LOGS: "commandbar-aggregated-job-logs",
  COMMANDBAR_STEPS: "commandbar-steps",
  COMMANDBAR_DOCUMENTATION: "commandbar-documentation",
  COMMANDBAR_SCRIPTS: "commandbar-scripts",
  COMMANDBAR_SYSTEM_DICTIONARY: "commandbar-system-dictionary",
  COMMANDBAR_STORGE_DICTIONARY: "commandbar-storage-dictionary",
  COMMANDBAR_VIRTUAL_USERS: "commandbar-virtual-users",
  COMMANDBAR_API_KEY: "commandbar-api-keys",
  COMMANDBAR_USER_GROUPS: "commandbar-user-groups",
  COMMANDBAR_PROJECT_CATEGORY: "commandbar-project-category",
  COMMANDBAR_USERS: "commandbar-users",
  COMMANDBAR_CODE_TEMPLATE_GROUPS: "commandbar-code-template-groups",
  COMMANDBAR_ITS: "commandbar-its",
  COMMANDBAR_ITS_RULE: "commandbar-its-rule",
  COMMANDBAR_LOCALIZATION: "commandbar-localization",
  COMMANDBAR_REPORT_TEMPLATE: "commandbar-report-template",
  COMMANDBAR_RECORDER_STEPS: "commandbar-recorder-steps",
  COMMANDBAR_SCHEDULER_DATA: "commandbar-scheduler-data",
  COMMANDBAR_WORK_ITEMS: "commandbar-work-items",
  COMMANDBAR_SCRIPT_LINKEDIN: "commandbar-script-linkedin",
  COMMANDBAR_SCHEDULER_GANTT: "commandbar-scheduler-gantt",
  COMMANDBAR_AUDIT_TRAIL: "commandbar-audit-trail",
  COMMANDBAR_DOC_SCRIPTS_TABLE: "commandbar-doc-scripts-table",
  COMMANDBAR_GUARDS: "commandbar-guards",
  COMMANDBAR_SCHEDULER_FLOW: "commandbar-scheduler-flow",
  COMMANDBAR_CODE_TEMPLATES: "commandbar-code-templates",

  COMMANDBAR_BUTTON_NEW: "commandbar-button-new",
  COMMANDBAR_BUTTON_REMOVE: "commandbar-button-remove",
  COMMANDBAR_BUTTON_REFRESH: "commandbar-button-refresh",
  COMMANDBAR_BUTTON_EDIT: "commandbar-button-edit",
  COMMANDBAR_BUTTON_SHOW_CLOSED: "commandbar-button-show-closed",
  COMMANDBAR_BUTTON_HIDE_CLOSED: "commandbar-button-hide-closed",
  COMMANDBAR_BUTTON_SHOW_INTERNAL: "commandbar-button-show-internal",
  COMMANDBAR_BUTTON_OPEN: "commandbar-button-open",
  COMMANDBAR_BUTTON_EXPORT: "commandbar-button-export",
  COMMANDBAR_BUTTON_EXPORT_CSV: "commandbar-button-export-csv",
  COMMANDBAR_BUTTON_EXPORT_CSV_DROPDOWN: "commandbar-button-export-csv-dropdown",
  COMMANDBAR_BUTTON_EXPORT_CSV_ADDITIONAL: "commandbar-button-export-csv-additional",
  COMMANDBAR_BUTTON_REORDER: "commandbar-button-reorder",
  COMMANDBAR_BUTTON_MARK_DEFAULT: "commandbar-button-mark-default",
  COMMANDBAR_BUTTON_OVERRIDE: "commandbar-button-override",
  COMMANDBAR_BUTTON_CLOSE: "commandbar-button-close",
  COMMANDBAR_BUTTON_SAVE: "commandbar-button-save",
  COMMANDBAR_BUTTON_CANCEL: "commandbar-button-cancel",
  COMMANDBAR_BUTTON_CREATE_GROUP: "commandbar-button-create-group",
  COMMANDBAR_BUTTON_MOVE_TO_GROUP: "commandbar-button-move-to-group",
  COMMANDBAR_BUTTON_FUNCTIONS: "commandbar-button-functions",
  COMMANDBAR_BUTTON_START_TIME: "commandbar-button-start-time",
  COMMANDBAR_BUTTON_SCRIPT_START_TIME: "commandbar-button-script-start-time",
  COMMANDBAR_BUTTON_ADD_TO_GROUP: "commandbar-button-add-to-group",
  COMMANDBAR_BUTTON_REMOVE_LINKS: "commandbar-button-remove-links",
  COMMANDBAR_BUTTON_DOWNLOAD: "commandbar-button-download",
  COMMANDBAR_BUTTON_COMPARE: "commandbar-button-compare",
  COMMANDBAR_BUTTON_SWITCH: "commandbar-button-switch",
  COMMANDBAR_BUTTON_STATISTIC: "commandbar-button-statistic",
  COMMANDBAR_BUTTON_GENERATE: "commandbar-button-generate",
  COMMANDBAR_BUTTON_GOTO: "commandbar-button-goto",
  COMMANDBAR_BUTTON_SHOW_DEVOPS_EXEC: "commandbar-button-show-devops-exec",
  COMMANDBAR_BUTTON_OPEN_TEST_SUIT_EXEC: "commandbar-button-open-test-suit-exec",
  COMMANDBAR_BUTTON_OPEN_TEST_RESULTS: "commandbar-button-open-test-results",
  COMMANDBAR_BUTTON_OPEN_TEST_CASE: "commandbar-button-open-test-case",
  COMMANDBAR_BUTTON_OPEN_TEST_SUIT: "commandbar-button-open-test-suit",
  COMMANDBAR_BUTTON_DEVOPS: "commandbar-button-devops",
  COMMANDBAR_BUTTON_CAPTURED_FILES: "commandbar-button-captured-files",
  COMMANDBAR_BUTTON_ERROR_WARNING_LIST: "commandbar-button-error-warning-list",
  COMMANDBAR_BUTTON_SCREENSHOT_GALLERY: "commandbar-button-screenshot-gallery",
  COMMANDBAR_BUTTON_TERMINATE: "commandbar-button-terminate",
  COMMANDBAR_BUTTON_DISABLE_JOBS: "commandbar-button-disable-jobs",
  COMMANDBAR_BUTTON_SWITCH_VIEW: "commandbar-button-switch-view",
  COMMANDBAR_BUTTON_STEP_SCRIPT: "commandbar-button-step-script",
  COMMANDBAR_BUTTON_REWORK: "commandbar-button-rework",
  COMMANDBAR_BUTTON_COPY: "commandbar-button-copy",
  COMMANDBAR_BUTTON_PAST: "commandbar-button-past",
  COMMANDBAR_BUTTON_OTHER: "commandbar-button-other",
  COMMANDBAR_BUTTON_ENABLE_STEPS: "commandbar-button-enable-steps",
  COMMANDBAR_BUTTON_DISABLE_STEPS: "commandbar-button-disable-steps",
  COMMANDBAR_BUTTON_SET_OPTIONAL: "commandbar-button-set-optional",
  COMMANDBAR_BUTTON_UNSET_OPTIONAL: "commandbar-button-unset-optional",
  COMMANDBAR_BUTTON_UNASSIGN: "commandbar-button-unassign",
  COMMANDBAR_BUTTON_CREATE_AND_ADD: "commandbar-button-create-and-add",
  COMMANDBAR_BUTTON_PLAY: "commandbar-button-play",
  COMMANDBAR_BUTTON_RECORD: "commandbar-button-record",
  COMMANDBAR_BUTTON_CLONE: "commandbar-button-clone",
  COMMANDBAR_BUTTON_SHOW_WORK_ITEMS: "commandbar-button-show-work-items",
  COMMANDBAR_BUTTON_HIDE_WORK_ITEMS: "commandbar-button-hide-work-items",
  COMMANDBAR_BUTTON_START: "commandbar-button-start",
  COMMANDBAR_BUTTON_IMPORT: "commandbar-button-import",
  COMMANDBAR_BUTTON_ERROR: "commandbar-button-error",
  COMMANDBAR_BUTTON_LOGOUT: "commandbar-button-logout",
  COMMANDBAR_BUTTON_AUTHENTICATE: "commandbar-button-authenticate",
  COMMANDBAR_BUTTON_REFRESH_APIKEY: "commandbar-button-refresh-apikey",
  COMMANDBAR_BUTTON_PASSWORD: "commandbar-button-password",
  COMMANDBAR_BUTTON_EDIT_TRANSLATIONS: "commandbar-button-edit-translations",
  COMMANDBAR_BUTTON_REMOVE_SELECTED: "commandbar-button-remove-selected",
  COMMANDBAR_BUTTON_EDIT_EXPANDED: "commandbar-button-edit-expanded",
  COMMANDBAR_BUTTON_DATA: "commandbar-button-data",
  COMMANDBAR_BUTTON_SET_REWORK: "commandbar-button-set-rework",
  COMMANDBAR_BUTTON_DRAW_OVERLAY: "commandbar-button-draw-overlay",
  COMMANDBAR_BUTTON_SHOW_AGGREGATED: "commandbar-button-aggregated-logs",
  COMMANDBAR_BUTTON_SHOW_NONAGGREGATED: "commandbar-button-nonaggregated-logs",
  COMMANDBAR_BUTTON_SHOW_ACTIVE_SESSIONS: "commandbar-button-show-active-sessions",
  COMMANDBAR_BUTTON_SHOW_LOGS: "commandbar-button-show-logs",
  COMMANDBAR_BUTTON_KPI_STATS: "commandbar-button-kpi-stats",
  COMMANDBAR_BUTTON_USED_IN: "commandbar-button-usedin",
  COMMANDBAR_BUTTON_ADD_SCRIPTS: "commandbar-button-add-scripts",

  FORM_BUTTON_SUBMIT: "form-button-submit",
  FORM_INPUT_NAME: "form-input-name",
  FORM_INPUT_LINK_NAME: "form-input-link-name",
  FORM_INPUT_DESCRIPTION: "form-input-description",
  FORM_INPUT_FORMAT: "form-input-format",
  FORM_INPUT_CREATED: "form-input-created",
  FORM_INPUT_NEXT_VAL: "form-input-next-val",
  FORM_INPUT_VALUE: "form-input-value",
  FORM_INPUT_DATEPICKER: "form-input-datapicker",
  FORM_INPUT_PASSWORD: "form-input-password",
  FORM_INPUT_CURRENT_PASSWORD: "form-input-current-password",
  FORM_INPUT_NEW_PASSWORD: "form-input-new-password",
  FORM_INPUT_REPEAT_PASSWORD: "form-input-repeat-password",
  FORM_INPUT_EMAIL: "form-input-email",
  FORM_INPUT_USERNAME: "form-input-username",
  FORM_INPUT_VERSION: "form-input-version",
  FORM_INPUT_TIMEOUT: "form-input-timeout",
  FORM_INPUT_MAX_PARALLEL_SESSIONS: "form-input-max-parallel-sessions",
  FORM_INPUT_START_URL: "form-input-start-url",
  FORM_INPUT_SYSTEM: "form-input-system",
  FORM_INPUT_DEFAULT_VU: "form-input-default-vu",
  FORM_INPUT_OCCURRENCES: "form-input-occurrences",
  FORM_INPUT_REPEAT_EVERY: "form-input-repeat-every",
  FORM_INPUT_NOTIFICATION_EMAIL: "form-input-notification-email",
  FORM_INPUT_INACTIVE_TIMEOUT: "form-input-inactive-timeout",
  FORM_INPUT_FIND_ELEMENT_TIMEOUT: "form-input-find-element-timeout",
  FORM_INPUT_POSITION_RESOLVER_META_SCORE_THRESHOLD:
    "form-input-position-resolver-meta-score-threshold",
  FORM_INPUT_GENERATE_RESOLVERS_ON_PLAYBACK: "form-input-generate-resolvers-on-playback",
  FORM_INPUT_OPTIONAL_FIND_ELEMENT_TIMEOUT: "form-input-optional-find-element-timeout",
  FORM_INPUT_OPTIONAL_REDIRECT_TIMEOUT: "form-input-optional-find-element-timeout",
  FORM_INPUT_ENV_NAME: "form-input-env-name",
  FORM_INPUT_ENV_ADDRESS: "form-input-env-address",
  FORM_INPUT_APPLICATION_NAME: "form-input-application-name",
  FORM_INPUT_ENV_TYPE: "form-input-env-type",
  FORM_INPUT_ACCOUNT: "form-input-account",
  FORM_INPUT_LOGIN: "form-input-login",
  FORM_INPUT_DOMAIN: "form-input-domain",
  FORM_INPUT_WORKSTATION: "form-input-workstation",
  FORM_INPUT_REAUTHENTICATION_INTERVAL: "form-input-reauthenticate-interval",
  FORM_INPUT_CUSTOM_SCRIPT_ID: "form-input-custom-script-id",
  FORM_INPUT_LOGIN_VALUE: "form-input-login-value",
  FORM_INPUT_PASSWORD_VALUE: "form-input-password-value",
  FORM_INPUT_HOST: "form-input-host",
  FORM_INPUT_TOKEN: "form-input-token",
  FORM_INPUT_REPEATER_MULTIPLIER: "form-input-repeater-multiplier",
  FORM_INPUT_GROUP_NAME: "form-input-group-name",
  FORM_INPUT_LABEL: "form-input-label",
  FORM_INPUT_DELAY: "form-input-delay",
  FORM_INPUT_COMMAND_ID: "form-input-command-id",
  FORM_INPUT_RULE_ID: "form-input-rule-id",
  FORM_INPUT_RULE_PLATFORM: "form-input-rule-platform",
  FORM_INPUT_RULE_VERSION: "form-input-rule-version",
  FORM_INPUT_PLATFORM_VERSION: "form-input-platform-version",
  FORM_INPUT_RECURRENCE_NUMBER: "form-input-recurrence-number",
  FORM_INPUT_LINENUM: "form-input-linenum",
  FORM_INPUT_FUNCTION_NAME: "form-input-function-name",
  FORM_INPUT_REPORT: "form-input-report",
  FORM_INPUT_RETRY: "form-input-retry",
  FORM_INPUT_VIRTUAL_DEVICE_NAME: "form-virtual-device-name",
  FORM_INPUT_PROJECT_NAME: "form-input-project-name",
  FORM_INPUT_CONSTANT: "form-input-constant",
  FORM_INPUT_EXPRESSION: "form-input-expression",
  FORM_INPUT_ADVANCED_CODE: "form-input-advanced-code",

  FORM_SELECT_PICKER: "form-select-picker",
  FORM_SELECT_TYPE: "form-select-type",
  FORM_SELECT_VALUE_TYPE: "form-select-value-type",
  FORM_SELECT_VALUE_SHORTCUT: "form-select-value-shortcut",
  FORM_SELECT_SSO: "form-select-sso",
  FORM_SELECT_DOCUMENTATION_LOCALE: "form-select-documentation-locale",
  FORM_SELECT_DOCUMENTATION_TEMPLATE: "form-select-documentation-template",
  FORM_SELECT_REPORT_TEMPLATE: "form-select-report-template",
  FORM_SELECT_REPORT_EXTENSION: "form-select-report-extension",
  FORM_SELECT_FILE_FORMAT: "form-select-file-format",
  FORM_SELECT_ITS: "form-select-its",
  FORM_SELECT_ROLES: "form-select-roles",
  FORM_SELECT_LANGUAGE: "form-select-language",
  FORM_SELECT_TRANSLATIONS: "form-select-translations",
  FORM_SELECT_CATEGORY: "form-select-category",
  FORM_SELECT_START_DATE: "form-select-category",
  FORM_SELECT_OWNERS: "form-select-owners",
  FORM_SELECT_STATUSES_TO_RUN: "form-select-statuses-to-run",
  FORM_SELECT_TAGS_TO_RUN: "form-select-tags-to-run",
  FORM_SELECT_TAGS: "form-select-tags",
  FORM_SELECT_PROJECT: "form-select-project",
  FORM_SELECT_SAVE_VIDEO_CONDITION: "form-select-save-video-condition",
  FORM_SELECT_SYSTEM: "form-select-system",
  FORM_SELECT_VU: "form-select-vu",
  FORM_SELECT_STORAGE: "form-select-storage",
  FORM_SELECT_TRIGGER_TYPE: "form-select-trigger-type",
  FORM_SELECT_SYSTEM_PLATFORM: "form-select-trigger-type",
  FORM_SELECT_STATISTICS_PRECISION: "form-select-statistics-precision",
  FORM_SELECT_STORAGE_TYPE: "form-select-storage-type",
  FORM_SELECT_PLATFORM: "form-select-platform",
  FORM_SELECT_RECORDER_POSITION: "form-select-recorder-position",
  FORM_SELECT_TTL: "form-select-ttl",
  FORM_SELECT_DEVOPS_PRIORITY: "form-select-devops-priority",
  FORM_SELECT_DEVOPS_AREA: "form-select-devops-area",
  FORM_SELECT_DEVOPS_ITERATION: "form-select-devops-iteration",
  FORM_SELECT_DEVOPS_PROJECT: "form-select-devops-project",
  FORM_SELECT_SEVERITY: "form-select-severity",
  FORM_SELECT_DEFAULT_PRIORITY: "form-select-default-priority",
  FORM_SELECT_DEFAULT_SEVERITY: "form-select-default-severity",
  FORM_SELECT_ITERATION: "form-select-iteration",
  FORM_SELECT_AREA: "form-select-area",
  FORM_SELECT_WORKITEM_TYPE: "form-select-workitem-type",
  FORM_SELECT_ISSUE_TRACKING: "form-select-issue-tracking",
  FORM_SELECT_TRIGGERS: "form-select-triggers",
  FORM_SELECT_PRIORITY: "form-select-priority",
  FORM_SELECT_STATUS: "form-select-status",
  FORM_SELECT_SYSTEM_DEFINITION: "form-select-system-definition",
  FORM_SELECT_REPEATER_TYPE: "form-select-repeater-type",
  FORM_SELECT_GROUP: "form-select-group",
  FORM_SELECT_SPECIAL_MODE: "form-select-special-mode",
  FORM_SELECT_VIRTUAL_USER: "form-select-virtual-user",
  FORM_SELECT_PLAYER_VISIBILITY: "form-select-player-visibility",
  FORM_SELECT_SLOW_EXECUTION: "form-select-slow-execution",
  FORM_SELECT_RECURRENCE_TYPE: "form-select-recurrence-type",
  FORM_SELECT_AZURE_CONFIGURATIONS: "form-select-azure-configurations",
  FORM_SELECT_SCHEDULER_ENVIRONMENT_OVERRIDE: "form-select-scheduler-environment-override",
  FORM_SELECT_SCHEDULER_VU_OVERRIDE: "form-select-scheduler-vu-override",
  FORM_SELECT_TEMPLATE_GROUP: "form-select-template-group",
  FORM_SELECT_TEMPLATE_CODE: "form-select-template-code",
  FORM_SELECT_ACTIVE_VERSION: "form-select-active-version",
  FORM_SELECT_DATASOURCE: "form-select-data-source",
  FORM_SELECT_CURRENTLY_MODIFIED_VERSION: "form-select-currently-modified-version",
  FORM_SELECT_DOCUMENTATION_MODE: "form-selecy-documentation-mode",
  FORM_WRAPPER_PROJECTS: "form-wrapper-projects",
  FORM_SELECT_DATASOURCE_ACTION: "form-selecy-datasource-action",
  FORM_SELECT_VIRTUAL_DEVICE_TYPE: "form-select-virtual-device-type",
  FORM_SELECT_ADD_AFTER_STEP: "form-select-add-after-step",
  FORM_SELECT_VARIABLE: "form-select-variable",
  FORM_SELECT_VARIABLE_TYPE: "form-select-variable-type",
  FORM_SELECT_DATASOURCE_SHEET: "form-select-datasource-sheet",
  FORM_SELECT_SAVE_AS: "form-select-save-as",
  FORM_SELECT_STEPS: "form-select-steps",
  FORM_SELECT_KPI: "form-select-kpi",

  FORM_CHECKBOX_DISABLE_START_STEP: "form-disable-start-step",
  FORM_CHECKBOX_ISACTIVE: "form-checkbox-is-active",
  FORM_CHECKBOX_DISPLAY_PUBLISHED: "form-checkbox-display-published",
  FORM_CHECKBOX_DOCUMENTATION_MODE: "form-checkbox-documentation-mode",
  FORM_CHECKBOX_AUTO_REFRESH_DATASOURCE: "form-checkbox-auto-refresh-datasource",
  FORM_CHECKBOX_CUSTOM_RESOLVERS_MODE: "form-checkbox-custom-resolvers-mode",
  FORM_CHECKBOX_SKIP_LINKED_SCRIPTS_IN_DOCUMENTATION:
    "form-checkbox-skip-linked-scripts-in-documentation",
  FORM_CHECKBOX_CLICK_ON_DISABLED_BUTTONS: "form-checkbox-click-on-disabled-buttons",
  FORM_CHECKBOX_HIDE_COMMENTS: "form-checkbox-hide-comments",
  FORM_CHECKBOX_SHOW_ACTION_HINT: "form-checkbox-show-action-hint",
  FORM_CHECKBOX_TAKE_SCREENSHOTS: "form-checkbox-take-screenshots",
  FORM_CHECKBOX_SCREENSHOTS_ON_INTERRUPTION: "form-checkbox-screenshots-on-interruption",
  FORM_CHECKBOX_MAP_TO_DEVOPS: "form-checkbox-map-to-devops",
  FORM_CHECKBOX_NOTIFY_BY_EMAIL: "form-checkbox-notify-by-email",
  FORM_CHECKBOX_CREDENTIAL_CONFIRMATION: "form-checkbox-credential-confirmation",
  FORM_CHECKBOX_MULTI_WINDOWS_SUPPORT: "form-checkbox-multi-windows-support",
  FORM_CHECKBOX_DISABLE_WEB_SECURITY: "form-checkbox-disable-web-security",
  FORM_CHECKBOX_IS_MANUAL: "form-checkbox-is-manual",
  FORM_CHECKBOX_REMEMBER_SENSITIVE_DATA: "form-checkbox-remember-sensitive-data",
  FORM_CHECKBOX_USE_CUSTOM_SCRIPT: "form-checkbox-use-custom-script",
  FORM_CHECKBOX_REAUTHENTICATE: "form-checkbox-reauthenticate",
  FORM_CHECKBOX_IS_SYSTEM_AUTH_ENABLED: "form-checkbox-is-system-auth-enabled",
  FORM_CHECKBOX_AUTO_START: "form-checkbox-auto-start",
  FORM_CHECKBOX_SLOW_MODE: "form-checkbox-slow-mode",
  FORM_CHECKBOX_EXPORT_TO_TEST_CASE: "form-checkbox-export-to-test-case",
  FORM_CHECKBOX_UPDATE_TEST_CASE_STATUS: "form-checkbox-update-test-case-status",
  FORM_CHECKBOX_MANUAL_LABEL: "form-checkbox-manual-label",
  FORM_CHECKBOX_IS_OPTIONAL: "form-checkbox-is-optional",
  FORM_CHECKBOX_IS_DISABLED: "form-checkbox-is-disabled",
  FORM_CHECKBOX_IS_ONE_TIME: "form-checkbox-is-optional",
  FORM_CHECKBOX_IS_TIMEOUT_ENABLED: "form-checkbox-is-optional",
  FORM_CHECKBOX_OVERWRITE_EXISTING_TRANSLATION: "form-checkbox-overwrite-existing-translation",
  FORM_CHECKBOX_OPEN_LINKS_IN_NEW_WINDOW: "form-checkbox-open-links-in-new-window",
  FORM_CHECKBOX_HELPER_TEXT: "form-checkbox-helper-text",
  FORM_CHECKBOX_DISABLE_IFRAME_SHARING: "form-checkbox-disable-iframe-sharing",
  FORM_CHECKBOX_REMOVE_EVERY: "form-checkbox-remove-every",
  FORM_CHECKBOX_ASSIGN_TO_SCRIPT: "form-checkbox-assign-to-script",
  FORM_CHECKBOX_OVERRIDE_STATUS: "form-checkbox-override-status",

  FORM_CHECKBOX_CSV_TAKE_SCREENSHOT: "form-checkbox-take-screenshot",
  FORM_CHECKBOX_CSV_SCREENSHOT_ON_INTERRUPTION: "form-checkbox-screenshot-on-interruption",
  FORM_CHECKBOX_CSV_CLICK_ON_DISABLED: "form-checkbox-click-on-disabled",
  FORM_CHECKBOX_CSV_USE_DEFAULT_ENV: "form-checkbox-use-default-env",
  FORM_CHECKBOX_CSV_TOP_PROJECT_NAME: "form-checkbox-top-project-name",
  FORM_CHECKBOX_CSV_VIRTUAL_USER_NAME: "form-checkbox-virtual-user-name",
  FORM_CHECKBOX_CSV_SCRIPT_LOG_URL: "form-checkbox-script-log-url",
  FORM_CHECKBOX_CSV_SCHEDULER_JOB_NAME: "form-checkbox-scheduler-job-name",
  FORM_CHECKBOX_CSV_JOB_ID: "form-checkbox-job-id",
  FORM_CHECKBOX_CSV_LAST_ERROR_MESSAGE: "form-checkbox-last-error-message",

  FORM_DATEPICKER_START_DATE: "form-datepicker-start-date",
  FORM_DATEPICKER_START_DATE_TIME: "form-datepicker-start-date-time",
  FORM_DATEPICKER_END_DATE_TIME: "form-datepicker-end-date-time",
  FORM_DATEPICKER_DUE_DATE: "form-datepicker-due-date",
  FORM_DATEPICKER_HELPER_TEXT: "form-datepicker-helper-text",

  FORM_TIMEPICKER_REMOVE_OLDER_THAN: "form-timepicker-remove-older-than",
  FORM_TIMEPICKER_REMOVE_ALL_AT: "form-timepicker-remove-all-at",

  FORM_AUTOCOMPLETE_REMOVE_DAYS: "form-autocomplete-remove-days",
  FORM_AUTOCOMPLETE_REMOVE_INTERVAL: "form-autocomplete-remove-interval",
  FORM_AUTOCOMPLETE_GUARD_REACTON: "form-autocomplete-guard-react-on",

  FORM_TEXTAREA_DESCRIPTION: "form-textarea-description",
  FORM_TEXTAREA_API_KEY: "form-textarea-api-key",
  FORM_TEXTAREA_COMMENT: "form-textarea-comment",

  FORM_RADIO_REACTION_TYPE: "form-radio-reaction-type",
  FORM_RADIO_MODE: "form-radio-mode",
  FORM_RADIO_RUN_MODE: "form-radio-run-mode",
  FORM_RADIO_URL_SWITCH: "form-radio-url-switch",
  FORM_RADIO_END_TYPE: "form-radio-end-type",
  FORM_RADIO_AUTH_TYPE: "form-radio-auth-type",
  FORM_RADIO_REMOVE_EVERY_OPTIONS: "form-radio-remove-every-options",
  FORM_RADIO_REMOVE_MANUALLY_OPTIONS: "form-radio-remove-manually-options",
  FORM_RADIO_RESOLVERS_MODE: "form-radio-resolvers-mode",
  FORM_RADIO_HELPER_TEXT: "form-radio-helper-text",
  FORM_RADIO_SCREENSHOTS: "form-radio-screenshots",
  FORM_RADIO_LOGICAL_OPERATOR: "form-radio-logical-operator",
  FORM_RADIO_ASSERT_TYPE: "form-radio-assert-type",

  FORM_SWITCH_USE_VIRTUAL_USER: "form-switch-use-virtual-user",
  FORM_SWITCH_DEFAULT_SYSTEM: "form-switch-default-system",
  FORM_SWITCH_DEFAULT_VU: "form-switch-default-vu",
  FORM_SWITCH_RECURRING: "form-switch-recurring",
  FORM_SWITCH_SINGLE_SIGN_ON: "form-switch-single-sign-on",
  FORM_SWITCH_IS_MANUAL_URL: "form-switch-is-manual-url",

  FORM_BUTTON_GO_TO: "form-button-go-to",
  FORM_BUTTON_FETCH_PROJECTS: "form-button-fetch-projects",
  FORM_BUTTON_ADD_CUSTOM_FIELDS: "form-button-add-custom-fields",

  FORM_TOGGLE_BUTTON_SYSTEM_SELECTION: "form-toggle-button-system-selection",

  FORM_IMPORT_SCRIPT: "form-import-scirpt",
  FORM_SCRIPTS_DETAILS: "form-scripts-details",
  FORM_SCRIPTS_DETAILS_IMPORT_DATASOURCE: "form-scripts-details-import-datasource",
  FORM_SCRIPT_DOCUMENTATION: "form-script-documentation",
  FORM_KPI: "form-kpi",
  FORM_SEQUENCES: "form-sequences",
  FORM_CODE_TEMPLATES: "form-code-templates",
  FORM_GLOBAL_VARIABLES: "form-global-variables",
  FORM_CHANGE_STATUS_SCRIPT: "form-change-status-script",

  ICON_COLLAPSE_DOC_SETTINGS: "icon-collapse-doc-settings",
  ICON_COLLAPSE_REPORT_SETTINGS: "icon-collapse-report-settings",
  ICON_COLLAPSE_ELEMENT_DETECTION_SETTINGS: "icon-collapse-element-detection-settings",
  ICON_COLLAPSE_ADVANCED_STEP_FIELDS: "icon-collapse-advanced-step-fields",
  ICON_CHECKED: "icon-checked",

  DROPZONE: "dropzone",
  BUTTON_DELETE: "button-delete",
  BUTTON_EDIT: "button-edit",
  BUTTON_GENERATE_TEMPLATE: "button-generate-template",
  BUTTON_ACTIVATE: "button-activate",
  BUTTON_DEACTIVATE: "button-deactivate",

  CHART_PPU_STATISTICS: "chart-ppu-statistics",

  ACTION_BUTTON_CHANGE_STATUS: "action-button-change-status",
  ACTION_BUTTON_CLONE: "action-button-clone",
  ACTION_BUTTON_EXPORT: "action-button-export",
  ACTION_BUTTON_START: "action-button-start",
  ACTION_BUTTON_PLAY: "action-button-play",
  ACTION_BUTTON_PLAY_OPTIONS: "action-button-play-options",
  ACTION_BUTTON_REWORK: "action-button-rework",
  ACTION_BUTTON_REWORK_OPTIONS: "action-button-rework-options",
  ACTION_BUTTON_RECORD: "action-button-record",
  ACTION_BUTTON_RECORD_OPTIONS: "action-button-record-options",

  EDITABLEAREA_BUTTON_EDIT: "editablearea-button-edit",
  EDITABLEAREA_BUTTON_CANCEL: "editablearea-button-cancel",
  EDITABLEAREA_BUTTON_SAVE: "editablearea-button-save",

  PANEL_BUTTON_SUBMIT_AND_RECORD: "panel-button-submit-and-record",
  PANEL_BUTTON_SUBMIT: "panel-button-submit",
  PANEL_BUTTON_CANCEL: "panel-button-cancel",
  PANEL_BUTTON_NEXT: "panel-button-next",
  PANEL_BUTTON_PREV: "panel-button-prev",

  LOADER: "loader",
  NOT_FOUND: "not-found",
  GANTT_FORM_TASK_DETAILS: "gantt-form-task-details",
  GANTT_FORM_TASK_DETAILS_NAME: "gantt-form-task-details-name",
  GANTT_FORM_TASK_DETAILS_DELAY: "gantt-form-task-details-delay",
  GANTT_FORM_TASK_DETAILS_REP_MULTIPLIER: "gantt-form-task-details-repeater-multiplier",
  GANTT_FORM_TASK_DETAILS_REP_TYPE: "gantt-form-task-details-repeater-type",
  GANTT_FORM_TASK_DETAILS_CONFIGURATION: "gantt-form-task-details-configuration",
  GANTT_FORM_TASK_DETAILS_DESCRIPTION: "gantt-form-task-details-description",
  GANTT_FORM_TASK_DETAILS_OVERRIDES: "gantt-form-task-details-overrides",
  GANTT_FORM_TASK_DETAILS_SUBMIT: "gantt-form-task-details-submit",
  GANTT_FORM_TASK_DETAILS_CLOSE: "gantt-form-task-details-close",

  GANTT_ADD_SCRIPTS_SUBMIT: "gantt-add-scripts-submit",
  GANTT_ADD_SCRIPTS_CANCEL: "gantt-add-scripts-cancel",

  GANTT_GROUP_SCRIPTS_SUBMIT: "gantt-group-scripts-submit",
  GANTT_GROUP_SCRIPTS_CANCEL: "gantt-group-scripts-cancel",

  SCHEDULERS: {
    FORM_ADD_NEW: "form-scheduler-add-new",
    FORM_ADD_NEW_NAME: "form-scheduler-add-new-name",
    FORM_ADD_NEW_START_TIME: "form-scheduler-add-new-start-time",
    FORM_ADD_NEW_RUN_MODE: "form-scheduler-add-new-run-mode",
    FORM_ADD_NEW_BUTTON: "form-scheduler-add-new-button",
    FORM_ADD_NEW_MORE_OPTIONS_BUTTON: "form-scheduler-add-new-more-options-button",
    FORM_ADD_NEW_CANCEL_BUTTON: "form-scheduler-add-new-cancel-button",

    FORM_CLONE: "form-scheduler-clone",
    FORM_CLONE_BUTTON: "form-scheduler-clone-button",
    FORM_CLONE_CANCEL_BUTTON: "form-scheduler-clone-cancel-button",
    FORM_CLONE_NAME: "form-scheduler-clone-name",
  },
  SCRIPTS: {
    ACTIONS: {
      EXPORT: "scripts-actions-export",
      CHANGE_STATUS: "scripts-actions-change-status",
      CLONE: "scripts-actions-clone",
      PLAY: "scripts-actions-play",
      PLAY_MORE: "scripts-actions-play-more",
      RECORD: "scripts-actions-record",
      RECORD_MORE: "scripts-actions-record-more",
    },
    FORM_PLAY_MODE: "form-script-play-mode",
    FORM_RECORD_MODE: "form-script-record-mode",
  },
  FORM_NEW_STEP: "form-script-new-step",
  FORM_NEW_STEP_SCRIPT: "form-new-step-script",
  FORM_NEW_STEP_ADD_ASSIGN_VALUE_TO_VARIABLE: "form-new-step-add-assign-value-to-variable",
  STEPS: {
    BUTTONS: {
      CREATE: "steps-button-create",
      CANCEL: "steps-button-cancel",
    },
    FORM: {
      ROOT: "steps-form",
      LABEL: "steps-form-label",
      DELAY: "steps-form-delay",
      OPTIONAL: "steps-form-optional",
      DISABLED: "steps-form-disabled",
      ONE_TIME: "steps-form-one-time",
      TIMEOUT: "steps-form-timeout",
      TIMEOUT_ENABLED: "steps-form-timeout-enabled",
      TERMINATION: "steps-form-termination",
      TERMINATION_ACTION: "steps-form-termination-action",
      TERMINATION_VALUE: "steps-form-termination-value",
      SCRIPT_UTILITIES: "steps-form-script-utilities",
      TAKE_SCREENSHOTS: "steps-form-take-screenshots",
      EVENT_TYPE: "steps-form-event-type",
      RULE_PLATFORM: "steps-form-rule-platform",
      RULE_ID: "steps-form-rule-id",
      RULE_VERSION: "steps-form-rule-version",
      PLATFORM_VERSION: "steps-form-platform-version",
      ORIGINAL_VALUE: "steps-form-original-value",
      COMMENT: "steps-form-comment",
    },
  },
};
