import { createRequest, getEndpoint } from "../requestCreator";

export const getCodeTemplates = createRequest(getEndpoint("codeTemplates.sub.get"));
export const deleteCodeTemplates = createRequest(getEndpoint("codeTemplates.sub.delete"));
export const uploadCodeTemplatesFile = createRequest(getEndpoint("codeTemplates.sub.import"), {
  useContentType: false,
  bodyBuilder: (params) => {
    const formData = new FormData();
    const { file, ...rest } = params;

    const valuesKeys = Object.keys(rest);

    if (file) {
      formData.append(file.name, file);
    }
    if (valuesKeys.length > 0) {
      valuesKeys.forEach((key) => {
        formData.append(key, params[key]);
      });
    }

    return formData;
  },
});
export const exportCodeTemplatesFile = createRequest(getEndpoint("codeTemplates.sub.export"), {
  fileRequest: true,
});

export const addNewCodeTemplate = createRequest(getEndpoint("codeTemplates.sub.create"));

export const updateNewCodeTemplate = createRequest(getEndpoint("codeTemplates.sub.update"));

export const getCodeTemplateGroups = createRequest(getEndpoint("codeTemplates.sub.groups.sub.get"));

export const deleteCodeTemplateGroup = createRequest(
  getEndpoint("codeTemplates.sub.groups.sub.delete"),
);

export const addNewCodeTemplateGroup = createRequest(
  getEndpoint("codeTemplates.sub.groups.sub.create"),
);

export const updateCodeTemplateGroup = createRequest(
  getEndpoint("codeTemplates.sub.groups.sub.update"),
);
