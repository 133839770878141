import { CodeTemplateWithGroup } from "@ea/shared_types/next/ea.types";
import { useMonaco } from "@monaco-editor/react";
import { useEffect } from "react";
import {
  generateDeclarationForCodeTemplates,
  generateDeclarationForVariablesGroup,
} from "../MonacoEditorField.helpers";
import { useVariablesGroups } from "./useVariablesGroups";
// eslint-disable-next-line
// @ts-ignore
// import EAFunctions from "@app/generated/eaFunctions.d.ts";
const EAFunctions = "";

export const useExtraLibs = (
  scriptId: number | undefined,
  codeTemplates?: CodeTemplateWithGroup[],
) => {
  const monacoInstance = useMonaco();
  const variablesGroups = useVariablesGroups(scriptId);

  useEffect(() => {
    if (!monacoInstance || variablesGroups.length === 0 || !scriptId) return;

    const declaration = variablesGroups.map(generateDeclarationForVariablesGroup).join("\n");
    const disposeVariables = monacoInstance.languages.typescript.javascriptDefaults.addExtraLib(
      declaration,
      "variableGroups.d.ts",
    );

    const disposeTemplates = codeTemplates
      ? monacoInstance.languages.typescript.javascriptDefaults.addExtraLib(
          generateDeclarationForCodeTemplates(codeTemplates),
          "codeTemplatesFunctions.d.ts",
        )
      : { dispose: () => {} };

    // eslint-disable-next-line
    const commonFunctionsArray = (EAFunctions as any).split("\n");
    commonFunctionsArray.splice(commonFunctionsArray.length - 2, 2);
    const disposeCommonFunctions =
      monacoInstance.languages.typescript.javascriptDefaults.addExtraLib(
        commonFunctionsArray.join("\n"),
        "commonFunctions.d.ts",
      );

    return () => {
      disposeVariables.dispose();
      disposeTemplates.dispose();
      disposeCommonFunctions.dispose();
    };
  }, [monacoInstance, variablesGroups, scriptId, codeTemplates]);
};
