import { PaginationProps as MiuPaginationProps } from "@mui/material/Pagination";
import { UsePaginationProps } from "@mui/material/usePagination";
import { FC, useMemo } from "react";
import { StyledPagination } from "./Pagination.styles";

interface PaginationProps extends MiuPaginationProps {
  perPage: number;
}

export const Pagination: FC<PaginationProps> = ({ page, onChange, count = 0, perPage }) => {
  const pages = useMemo(() => Math.floor(count / perPage), [count, perPage]);
  const onChangeHandle: UsePaginationProps["onChange"] = (event, page) => {
    if (onChange) onChange(event, page);
  };

  return pages ? <StyledPagination count={pages} page={page} onChange={onChangeHandle} /> : null;
};
