import {
  downloadDocumentsTemplate,
  getDocumentsTemplates,
  updateDocumentsTemplate,
  uploadDocumentsTemplate,
} from "../../api/endpoints";
import { createMutation, createQuery } from "./apiHooks";

export const useGetDocumentsTemplates = createQuery(getDocumentsTemplates, {
  queryKey: ["DocumentsTemplates", "Get"],
});

export const useUploadDocumentsTemplate = createMutation(uploadDocumentsTemplate, {
  mutationKey: ["DocumentsTemplates", "Upload"],
});

export const useUpdateDocumentsTemplate = createMutation(updateDocumentsTemplate, {
  mutationKey: ["DocumentsTemplates", "Update"],
});

export const useDownloadDocumentsTemplate = createMutation(downloadDocumentsTemplate, {
  mutationKey: ["DocumentsTemplates", "Download"],
});
