"use client";
import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
  useTheme,
} from "@mui/material";
import { Palette } from "@mui/material/styles";

type ThemeColorKeys = keyof Palette;

interface TypographyProps extends Omit<MuiTypographyProps, "color"> {
  color?:
    | "neutral50"
    | "neutral300"
    | "neutral600"
    | "neutral700"
    | "neutral900"
    | "textInverted"
    | ThemeColorKeys
    | `${ThemeColorKeys}.${string}`;
}

export const Typography = ({ children, color, ...props }: TypographyProps) => {
  const theme = useTheme();

  let mappedColor: string | undefined;
  switch (color) {
    case "neutral50":
      mappedColor = theme.palette.neutral[50];
      break;
    case "neutral300":
      mappedColor = theme.palette.neutral[300];
      break;
    case "neutral600":
      mappedColor = theme.palette.neutral[600];
      break;
    case "neutral700":
      mappedColor = theme.palette.neutral[700];
      break;
    case "neutral900":
      mappedColor = theme.palette.neutral[900];
      break;
    case "textInverted":
      mappedColor = theme.palette.mode === "dark" ? "black" : "white";
      break;

    default:
      mappedColor = color;
  }

  return (
    <MuiTypography color={mappedColor} {...props}>
      {children}
    </MuiTypography>
  );
};
