import MUIButton, { ButtonProps as MUIButtonProps } from "@mui/material/Button";
import { forwardRef, useContext } from "react";
import { DrawerContext } from "../Drawer/DrawerContext";

export type ButtonProps = MUIButtonProps & {
  width?: string;
};

export const BUTTON_SIZES = {
  STANDARD: "134px",
  AUTO: "auto",
  DRAWER: "332px",
  MORE: "106px",
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { children, width, color = "secondary", sx, ...props },
  ref,
) {
  const insideDrawer = useContext(DrawerContext);
  const defaultWith = width || (insideDrawer ? BUTTON_SIZES.DRAWER : BUTTON_SIZES.STANDARD);
  return (
    <MUIButton
      ref={ref}
      color={color}
      sx={{
        width: defaultWith,
        ...sx,
      }}
      {...props}
    >
      {children}
    </MUIButton>
  );
});
