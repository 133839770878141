import {
  createStep,
  deleteSteps,
  downloadStepFile,
  editStep,
  editSteps,
  getSteps,
  moveStep,
  pasteStep,
  reorderSteps,
  uploadTemporaryFile,
} from "../../api/endpoints/steps";
import { createMutation, createQuery } from "./apiHooks";

export const ALL_SCRIPT_STEPS_QUERY_KEY = ["Steps", "Get"];
export const SCRIPT_STEPS_QUERY_KEY = (scriptId: number) => [
  ...ALL_SCRIPT_STEPS_QUERY_KEY,
  `${scriptId}`,
];

export const useGetSteps = createQuery(getSteps, {
  queryKey: ALL_SCRIPT_STEPS_QUERY_KEY,
});

export const useEditStep = createMutation(
  editStep,
  {
    mutationKey: ["Steps", "Edit"],
  },
  ALL_SCRIPT_STEPS_QUERY_KEY,
);

export const useEditSteps = createMutation(
  editSteps,
  {
    mutationKey: ["Steps", "EditAll"],
  },
  ALL_SCRIPT_STEPS_QUERY_KEY,
);

export const useCreateStep = createMutation(
  createStep,
  {
    mutationKey: ["Steps", "Create"],
  },
  ALL_SCRIPT_STEPS_QUERY_KEY,
);

export const usePasteStep = createMutation(
  pasteStep,
  {
    mutationKey: ["Steps", "Paste"],
  },
  ALL_SCRIPT_STEPS_QUERY_KEY,
);

export const useMoveStep = createMutation(
  moveStep,
  {
    mutationKey: ["Steps", "Move"],
  },
  ALL_SCRIPT_STEPS_QUERY_KEY,
);

export const useReorderSteps = createMutation(
  reorderSteps,
  {
    mutationKey: ["Steps", "Reorder"],
  },
  ALL_SCRIPT_STEPS_QUERY_KEY,
);

export const useUploadTemporaryFile = createMutation(uploadTemporaryFile, {
  mutationKey: ["Steps", "UploadTemporaryFile"],
});

export const useDownloadStepFile = createMutation(downloadStepFile, {
  mutationKey: ["Steps", "DownloadStepFile"],
});

export const useDeleteSteps = createMutation(
  deleteSteps,
  {
    mutationKey: ["Steps", "Delete"],
  },
  ALL_SCRIPT_STEPS_QUERY_KEY,
);
