"use client";
import { CodeType } from "@ea/shared_types/next/ea.enums";
import { AdvancedCodeFunctionName } from "@ea/shared_types/next/ea.types";
import { EA_UTILS } from "@ea/shared_types/next/ea.utils";
import { EditorProps } from "@monaco-editor/react";
import { useContext, useEffect } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import { CodeEditor, CodeEditorProps } from "../CodeEditor";
import { FormHelperText } from "../materialUI";
import { EditableContext } from "./utils";

type FormCodeEditorProps = {
  name: string;
  monacoEditorProps?: EditorProps;
  codeEditorProps?: CodeEditorProps;
};

const FUNCTION_NAME: AdvancedCodeFunctionName = "eaExecuteVariableValue";

export const FormCodeEditor = ({
  name,
  monacoEditorProps,
  codeEditorProps,
}: FormCodeEditorProps) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const editable = useContext(EditableContext);
  const value = watch(name) || "";
  const fieldError = EA_UTILS.object.getNestedValue(errors, name) as FieldError | undefined;

  useEffect(() => {
    register(name);
  }, [name, register]);

  const getWrappedValue = (value: string, mode?: CodeType): string => {
    switch (mode) {
      case CodeType.ADVANCED:
        return `function ${FUNCTION_NAME}() { \n${value}\n}`;
      case CodeType.CODE_TEMPLATE:
        return `\n${value}\n`;
      case CodeType.EXPRESSION:
        return "`" + `\n${value}\n` + "`";
      default:
        return value;
    }
  };

  const getUnwrappedValue = (value: string, mode?: CodeType): string => {
    switch (mode) {
      case CodeType.ADVANCED:
        return value
          .substring(0, value.lastIndexOf("\n}"))
          .replace(`function ${FUNCTION_NAME}() { \n`, "");
      case CodeType.CODE_TEMPLATE:
        return value.trim();
      case CodeType.EXPRESSION:
        return value.substring(1, value.length - 1).trim();
      default:
        return value;
    }
  };

  const handleEditorChange = (editorValue: string) => {
    setValue(name, getWrappedValue(editorValue, codeEditorProps?.mode), { shouldDirty: true });
  };

  return (
    <>
      <CodeEditor
        readOnly={!editable}
        initialHeight={codeEditorProps?.mode === CodeType.EXPRESSION ? 150 : undefined}
        onChange={handleEditorChange}
        error={!!fieldError}
        monacoEditorProps={{
          value: getUnwrappedValue(value, codeEditorProps?.mode),
          ...monacoEditorProps,
        }}
        {...codeEditorProps}
      />
      {fieldError && <FormHelperText error>{fieldError.message}</FormHelperText>}
    </>
  );
};
