"use client";
import { PaletteExtendedColor } from "@mui/material";
import MuiAccordion, { AccordionProps as MuiAccordionProps } from "@mui/material/Accordion";
import { styled } from "@mui/material/styles";

interface AccordionProps extends MuiAccordionProps {
  styleVariant?: "primary" | "secondary";
}

const StyledAccordion = styled(MuiAccordion, {
  shouldForwardProp: (prop) => prop !== "styleVariant" && prop !== "expanded",
})<AccordionProps>(({ styleVariant, theme }) => ({
  ...(styleVariant === "secondary" && {
    backgroundColor: (theme.palette.secondary as PaletteExtendedColor)[50],
    border: "none",
  }),
}));

export const CustomAccordion: React.FC<AccordionProps> = (props) => {
  return <StyledAccordion {...props} />;
};

export { CustomAccordion as Accordion };
