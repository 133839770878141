"use client";
import { EA_UTILS } from "@ea/shared_types/next/ea.utils";
import React, { useContext } from "react";
import { FieldError, FieldValues, RegisterOptions, useFormContext } from "react-hook-form";
import { InputAdornment, TextField, TextFieldProps, TextFieldVariants } from "../materialUI";
import { EditableContext } from "./utils";
type FormTextFieldProps = TextFieldProps & {
  name: string;
  size?: "small" | "medium";
  variant?: TextFieldVariants;
  fullWidth?: boolean;
  dataTestid: string;
  iconStart?: React.ReactNode;
  iconEnd?: React.ReactNode;
  readOnly?: boolean;
  allowNegativeNumbers?: boolean;
  options?: RegisterOptions<FieldValues, string>;
  minValue?: number;
};

export const FormTextField = React.forwardRef(
  (
    {
      name,
      size = "small",
      fullWidth = true,
      variant = "outlined",
      type,
      dataTestid,
      iconStart,
      iconEnd,
      sx,
      readOnly,
      allowNegativeNumbers,
      options,
      minValue = 0,
      ...props
    }: FormTextFieldProps,
    ref,
  ) => {
    const {
      register,
      formState: { errors },
    } = useFormContext();

    const fieldError = EA_UTILS.object.getNestedValue(errors, name) as FieldError | undefined;

    const inputProps: { startAdornment?: React.ReactNode; endAdornment?: React.ReactNode } = {};

    if (iconStart) {
      inputProps.startAdornment = (
        <InputAdornment position="start" sx={(theme) => ({ color: theme.palette.secondary[500] })}>
          {iconStart}
        </InputAdornment>
      );
    }
    if (iconEnd) {
      inputProps.endAdornment = (
        <InputAdornment position="end" sx={(theme) => ({ color: theme.palette.secondary[500] })}>
          {iconEnd}
        </InputAdornment>
      );
    }

    const contextReadOnly = !useContext(EditableContext);
    const editable = !(readOnly ?? contextReadOnly);

    const isNumberInput = type === "number";

    const defaultNumberProps =
      isNumberInput && !allowNegativeNumbers
        ? {
            inputProps: {
              min: minValue,
            },
          }
        : {};

    const onKeyDown =
      isNumberInput && !allowNegativeNumbers
        ? (e) => {
            // prevent from typing negative number
            if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
              e.preventDefault();
            }
          }
        : undefined;

    return (
      <TextField
        {...register(name, options)}
        size={size}
        variant={variant}
        type={type}
        fullWidth={fullWidth}
        error={!!fieldError}
        helperText={fieldError?.message || ""}
        data-testid={dataTestid}
        sx={sx}
        {...props}
        inputProps={{
          readOnly: !editable,
          disabled: !editable,
          ...props.inputProps,
        }}
        onKeyDown={onKeyDown}
        InputProps={{
          ...inputProps,
          ...defaultNumberProps,
          readOnly: !editable,
          disabled: !editable,
          ...props.InputProps,
        }}
        classes={{
          root: editable ? "" : "MuiReadOnly",
        }}
        focused={false}
      />
    );
  },
);
FormTextField.displayName = "FormTextField";
