"use client";
import React from "react";
import { useFieldArray } from "react-hook-form";
import { AddAction, DeleteAction } from "../ActionsBar";
import { Typography } from "../materialUI";
import { FormInputsWrapper } from "./FormInputsWrapper";

type FormArrayFieldProps = {
  disabled?: boolean;
  title: string | React.ReactNode;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderRow: (row: any, index: number) => React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getNewValue: () => any;
};

export const FormArrayField = ({
  disabled = false,
  title,
  name,
  renderRow,
  getNewValue,
}: FormArrayFieldProps) => {
  const { fields, remove, append } = useFieldArray({ name });
  return (
    <>
      <FormInputsWrapper
        direction="row"
        justifyContent="space-between"
        sx={(theme) => ({
          backgroundColor: theme.palette.grey[100],
        })}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            paddingLeft: 2,
          }}
        >
          {title}
        </Typography>
        {!disabled ? (
          <AddAction
            variant="icon"
            onClick={() => {
              append(getNewValue());
            }}
          />
        ) : null}
      </FormInputsWrapper>

      {fields.map((row, index) => {
        return (
          <FormInputsWrapper key={index} direction="row" gap={0.5} flexGrow={1}>
            <FormInputsWrapper gap={0.5}>{renderRow(row, index)}</FormInputsWrapper>
            {!disabled ? <DeleteAction variant="icon" onClick={() => remove(index)} /> : null}
          </FormInputsWrapper>
        );
      })}
    </>
  );
};
