import { createVersionKey } from "@ea/shared_types/helpers/codeTemplates.helpers";
import { CodeTemplateWithGroup, VariablesGroup } from "@ea/shared_types/next/ea.types";

const generateDeclarationForVariableGroupVariables = (variables) =>
  variables.map((variable) => `${variable.name}: any`).join(",\n");
const generateDeclarationForVariableGroupGroups = (groups: VariablesGroup[]) => {
  return groups
    .map((group) => {
      if (group.variables) {
        return `
      ${group.id}: {
        ${generateDeclarationForVariableGroupVariables(group.variables)}
      }`;
      }
      if (group.groups) {
        return `
        ${group.id}: {
          ${generateDeclarationForVariableGroupGroups(group.groups)}
        }`;
      }
      return "";
    })
    .join(",\n");
};

export const generateDeclarationForVariablesGroup = ({ variables, groups, id }: VariablesGroup) => {
  const variablesBody =
    variables && variables.length > 0
      ? generateDeclarationForVariableGroupVariables(variables)
      : "";
  const groupsBody =
    groups && groups.length > 0 ? generateDeclarationForVariableGroupGroups(groups) : "";
  const declaration = `
    declare const ${id} = {
      ${[variablesBody, groupsBody].filter((v) => v !== "").join(",\n")}
    }
  `;

  return declaration;
};

export const generateDeclarationForCodeTemplates = (codeTemplates: CodeTemplateWithGroup[]) => {
  const createCodeTemplatesMap = (codeTemplates: CodeTemplateWithGroup[]) =>
    codeTemplates.reduce((container, codeTemplate) => {
      const groupName = codeTemplate.codeTemplateGroup.name;
      if (!container[groupName]) {
        container[groupName] = {};
      }

      container[groupName][codeTemplate.functionName] =
        codeTemplate.definitions[createVersionKey(codeTemplate.activeVersion)]; // WORK shared types implementation version is slightly different than the one from administration next
      return container;
    }, {});
  const codeTemplatesGroupMap = createCodeTemplatesMap(codeTemplates);
  const codeTemplateGroupNames = Object.keys(codeTemplatesGroupMap);
  const declaration = `declare const CodeTemplates = {${codeTemplateGroupNames
    .map((groupName) => {
      return `${groupName}: {${Object.keys(codeTemplatesGroupMap![groupName])
        .map((functionName) => {
          return `${functionName}: (${(
            codeTemplatesGroupMap![groupName][functionName].params || []
          ).map((a) => a.name)}) => any`;
        })
        .join(",")}}`;
    })
    .join(",")}}`;

  return declaration;
};
