"use client";
import { StyledComponent } from "@emotion/styled";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { SvgIconProps } from "@mui/material/SvgIcon";
import { Theme, styled } from "@mui/material/styles";

export const CustomHelpOutlineIcon: StyledComponent<SvgIconProps & { theme?: Theme }, object> =
  styled(HelpOutlineIcon)(({ theme }: { theme: Theme }) => ({
    color: theme.palette.secondary[300],
  }));
