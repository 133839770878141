import { FORMDATA_FIELD_NAMES } from "@ea/shared_types/next/ea.consts";
import { getEndpointWithClientParams } from "@ea/shared_types/next/ea.endpoints";
import { createRequest, getEndpoint } from "../requestCreator";
import { requestTags } from "../requestTags";

type LocalizationImportParams = {
  file: File;
};

export const importLocalizations = createRequest(
  getEndpointWithClientParams<LocalizationImportParams>()("localizations.sub.import"),
  {
    useContentType: false,
    bodyBuilder: (params) => {
      const { file, ...rest } = params;

      const formData = new FormData();
      const valuesKeys = Object.keys(rest);

      if (file) {
        formData.append(FORMDATA_FIELD_NAMES.translation, file, file.name);
      }

      if (valuesKeys.length > 0) {
        valuesKeys.forEach((key) => {
          formData.append(key, params[key]);
        });
      }

      return formData;
    },
  },
);

export const createLocalizations = createRequest(getEndpoint("localizations.sub.create"));
export const getLocalizations = createRequest(getEndpoint("localizations.sub.get"), {
  next: { tags: [requestTags.scriptTranslationsRequestTags.getLocalization] },
});
export const deleteLocalizations = createRequest(getEndpoint("localizations.sub.delete"), {
  pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
});

export const updateLocalizations = createRequest(getEndpoint("localizations.sub.update"), {
  pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
});

export const exportLocalizations = createRequest(getEndpoint("localizations.sub.export"), {
  pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  fileRequest: true,
});

export const getTranslation = createRequest(getEndpoint("localizations.sub.getOne"), {
  next: { tags: [requestTags.scriptTranslationsRequestTags.getLocalization] },
});
