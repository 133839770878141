export const requestTags = {
  userRequestTags: { getUsers: "userCollection" },
  scriptTranslationsRequestTags: { getLocalization: "getLocalization" },
  scriptsRequestTags: {
    getScriptById: (id: number) => `script-${id}`,
    getLinkedMap: (id: number) => `script-linkedMap-${id}`,
  },
  schedulersRequestTags: {
    getSchedulers: "getSchedulers",
  },
  globalSettingsRequestTags: {
    getGlobalSettings: "getGlobalSettings",
    getScreenshotsAutoCleanup: "getScreenshotsAutoCleanup",
  },
};
