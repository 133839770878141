import { createRequest, getEndpoint } from "../requestCreator";

export const getVirtualUser = createRequest(getEndpoint("virtualUsers.sub.get"));
export const countVirtualUser = createRequest(getEndpoint("virtualUsers.sub.count"));
export const createVirtualUser = createRequest(getEndpoint("virtualUsers.sub.create"));
export const updateVirtualUser = createRequest(getEndpoint("virtualUsers.sub.update"), {
  pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
});
export const deleteVirtualUser = createRequest(getEndpoint("virtualUsers.sub.delete"), {
  pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
});
export const authenticateVirtualUser = createRequest(getEndpoint("virtualUsers.sub.authenticate"));
export const getVirtualUserPool = createRequest(getEndpoint("virtualUsers.sub.pool.sub.get"));
export const createVirtualUserPool = createRequest(getEndpoint("virtualUsers.sub.pool.sub.create"));
export const updateVirtualUserPool = createRequest(getEndpoint("virtualUsers.sub.pool.sub.update"));
export const deleteVirtualUserPool = createRequest(getEndpoint("virtualUsers.sub.pool.sub.delete"));
