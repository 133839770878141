import {
  assignGlobalExcelToScript,
  countDatasource,
  deleteDatasource,
  downloadDatasource,
  getDatasource,
  getDatasourceTemplate,
  unassignGlobalExcelFromScript,
  updateGlobalExcel,
  uploadDatasource,
  uploadGlobalExcel,
} from "../../api/endpoints";
import { createMutation, createQuery } from "./apiHooks";

export const useUploadDatasource = createMutation(uploadDatasource, {
  mutationKey: ["Datasource", "UploadFile"],
});

export const useGetDatasource = createQuery(getDatasource, {
  queryKey: ["Datasource", "Get"],
});
export const useCountDatasource = createQuery(countDatasource, {
  queryKey: ["Datasource", "Count"],
});

export const useGetDatasourceTemplate = createMutation(getDatasourceTemplate, {
  mutationKey: ["Datasource", "GetDataSourceTemplate"],
});

export const useDownloadDatasource = createMutation(downloadDatasource, {
  mutationKey: ["Datasource", "Download"],
});

export const useDeleteDatasource = createMutation(deleteDatasource, {
  mutationKey: ["Datasource", "Delete"],
});

export const useUploadGlobalExcel = createMutation(uploadGlobalExcel, {
  mutationKey: ["GlobalExcel", "Upload"],
});

export const useUpdateGlobalExcel = createMutation(updateGlobalExcel, {
  mutationKey: ["GlobalExcel", "Update"],
});

export const useAssignGlobalExcelToScript = createMutation(assignGlobalExcelToScript, {
  mutationKey: ["GlobalExcel", "Assign"],
});
export const useUnassignGlobalExcelFromScript = createMutation(unassignGlobalExcelFromScript, {
  mutationKey: ["GlobalExcel", "Unassign"],
});
