import { convertToFormData } from "../helpers/converter";
import { createRequest, getEndpoint, getEndpointWithClientParams } from "../requestCreator";
import { requestTags } from "../requestTags";

export const getGlobalSettings = createRequest(getEndpoint("settings.sub.global.sub.get"), {
  next: { tags: [requestTags.globalSettingsRequestTags.getGlobalSettings] },
});

export interface UploadFileParams {
  file?: File | undefined;
}

export const updateGlobalSettings = createRequest(
  getEndpointWithClientParams<UploadFileParams>()("settings.sub.global.sub.update"),
  {
    useContentType: false,
    bodyBuilder: (params) => {
      const formData = new FormData();

      const { file, ...rest } = params;

      const data = convertToFormData(rest);
      const valuesKeys = Object.keys(data);

      if (file) {
        formData.append("file", file);
      }
      if (valuesKeys.length > 0) {
        valuesKeys.forEach((key) => {
          formData.append(key, data[key]);
        });
      }
      return formData;
    },
  },
);
export const getScreenshotsAutoCleanup = createRequest(
  getEndpoint("settings.sub.internal.sub.screenshot.sub.get"),
  {
    next: { tags: [requestTags.globalSettingsRequestTags.getScreenshotsAutoCleanup] },
  },
);
export const setScreenshotsAutoCleanup = createRequest(
  getEndpoint("settings.sub.internal.sub.screenshot.sub.set"),
);

export const deleteScreenshotsManually = createRequest(getEndpoint("screenshots.sub.delete"));
