import ClientUserError from "@ea/shared_types/ea.errors";

export type ErrorType = {
  message: string;
  type?: string;
  code?: string;
};

// eslint-disable-next-line
export const unpackError = (result: any): ErrorType => {
  if (!result) {
    return {
      message: "Request not valid",
    };
  }

  if (result.error && typeof result.error === "object") {
    return result.error;
  }

  if (typeof result === "string" || typeof result.error === "string") {
    return {
      message: result,
    };
  }

  return result;
};

// eslint-disable-next-line
export const isUserError = (arg: any): arg is ClientUserError => arg.type === "USER";
