"use client";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Typography } from "../materialUI";
import { StyledPaper } from "./FileContainer.styles";

interface DropzoneProps {
  filename: string;
  onClick?: () => void;
}

export const FileContainer = ({ filename, onClick }: DropzoneProps) => {
  return (
    <StyledPaper sx={{ mb: 2 }} elevation={0}>
      <Typography
        textAlign="center"
        display="flex"
        alignItems="center"
        fontWeight={500}
        variant="h5"
        color="neutral900"
      >
        {filename}
      </Typography>

      {onClick && (
        <IconButton onClick={onClick}>
          <DeleteIcon />
        </IconButton>
      )}
    </StyledPaper>
  );
};
