import { createRequest, getEndpoint } from "../requestCreator";
import { requestTags } from "../requestTags";

export const loginUser = createRequest(getEndpoint("users.sub.login"), { public: true });
export const logoutUser = createRequest(getEndpoint("users.sub.logout"));

export const getUsers = createRequest(getEndpoint("users.sub.get"), {
  next: { tags: [requestTags.userRequestTags.getUsers] },
});

export const getAuthenticatedUser = createRequest(getEndpoint("users.sub.auth"));

export const editUser = createRequest(getEndpoint("users.sub.update"), {
  pathCreator: (path, params) => path.replace(":id", `${params.id}`),
});

export const changePassword = createRequest(getEndpoint("users.sub.changePassword"));

export const deleteUser = createRequest(getEndpoint("users.sub.delete"), {
  pathCreator: (path, params) => path.replace(":id", `${params.id}`),
});

export const createUser = createRequest(getEndpoint("users.sub.create"));

export const getRoles = createRequest(getEndpoint("users.sub.roles.sub.get"));

export const getUserGroups = createRequest(getEndpoint("users.sub.groups.sub.get"));
export const getUserGroupsCount = createRequest(getEndpoint("users.sub.groups.sub.count"));

export const deleteUsersGroup = createRequest(getEndpoint("users.sub.groups.sub.delete"), {
  pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
});

export const createUsersGroup = createRequest(getEndpoint("users.sub.groups.sub.create"));

export const editUsersGroup = createRequest(getEndpoint("users.sub.groups.sub.update"), {
  pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
});
