"use client";
import { useCallback, useRef, useState } from "react";

export const useResizable = (initialHeight: number) => {
  const [height, setHeight] = useState<number>(initialHeight);
  const resizeHandleRef = useRef<HTMLDivElement | null>(null);

  const handleMouseDown = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      const startHeight = height;
      const startY = event.clientY;
      const handleMouseMove = (moveEvent: MouseEvent) => {
        const delta = moveEvent.clientY - startY;
        setHeight(Math.max(200, startHeight + delta));
      };

      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    },
    [height],
  );

  return { height, resizeHandleRef, handleMouseDown };
};
