"use client";

import { DatePicker, DatePickerProps, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

type FormDatePickerProps<TData> = DatePickerProps<TData> & {
  name: string;
};

export function CustomDatePicker<TData>({
  format = "YYYY-MM-DD",
  ...props
}: FormDatePickerProps<TData>) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        format={format}
        slotProps={{ textField: { InputProps: { size: "small" } } }}
        {...props}
      />
    </LocalizationProvider>
  );
}
