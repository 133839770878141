import MuiTooltip, { TooltipProps as MuiTooltipProps } from "@mui/material/Tooltip";
import { CustomHelpOutlineIcon } from "../CustomHelpOutlineIcon";

type Props = Omit<MuiTooltipProps, "children"> & {
  children?: MuiTooltipProps["children"];
};

export const Tooltip = (props: Props) => (
  <MuiTooltip {...props}>{props.children || <CustomHelpOutlineIcon />}</MuiTooltip>
);
