"use client";
import { EA_UTILS } from "@ea/shared_types/next/ea.utils";
import { ReactNode, useContext } from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import {
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  FormHelperText,
  Switch,
  SwitchProps,
} from "../materialUI";
import { EditableContext } from "./utils";

interface FormSwitchProps extends SwitchProps {
  name: string;
  label?: ReactNode;
  dataTestidFormControl: string;
  dataTestidHelperText?: string;
  formControlLabelProps?: Omit<FormControlLabelProps, "control" | "label">;
}

export const FormSwitch = ({
  name,
  label,
  dataTestidFormControl,
  dataTestidHelperText,
  formControlLabelProps,
  ...switchProps
}: FormSwitchProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const fieldError = EA_UTILS.object.getNestedValue(errors, name) as FieldError | undefined;
  const editable = useContext(EditableContext);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }) => (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                color="secondary"
                disabled={!editable}
                {...switchProps}
                onBlur={onBlur}
                onChange={(e) => {
                  onChange(e);
                  switchProps?.onChange?.(e, e.target.checked);
                }}
                checked={!!value}
              />
            }
            label={label}
            data-testid={dataTestidFormControl}
            {...formControlLabelProps}
          />
          {fieldError && (
            <FormHelperText
              data-testid={
                !dataTestidHelperText
                  ? `${dataTestidFormControl}-helper-text`
                  : dataTestidHelperText
              }
              error
            >
              {fieldError?.message}
            </FormHelperText>
          )}
        </FormGroup>
      )}
    />
  );
};
