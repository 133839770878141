import { getEndpointWithClientParams } from "@ea/shared_types/next/ea.endpoints";
import { createRequest, getEndpoint } from "../requestCreator";

export interface UploadDocumentsTemplateParams {
  file: File | null;
}
export interface UpdateDocumentsTemplateParams {
  id: number;
}

export const uploadDocumentsTemplate = createRequest(
  getEndpointWithClientParams<UploadDocumentsTemplateParams>()("reportTemplates.sub.upload"),
  {
    useContentType: false,
    bodyBuilder: (params) => {
      const formData = new FormData();
      const { file, ...rest } = params;

      const valuesKeys = Object.keys(rest);

      if (file) {
        formData.append(file.name, file);
      }
      if (valuesKeys.length > 0) {
        valuesKeys.forEach((key) => {
          formData.append(key, params[key]);
        });
      }
      return formData;
    },
  },
);
export const getDocumentsTemplates = createRequest(getEndpoint("reportTemplates.sub.get"));
export const deleteDocumentsTemplate = createRequest(getEndpoint("reportTemplates.sub.delete"), {
  pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
});
export const updateDocumentsTemplate = createRequest(
  getEndpointWithClientParams<UpdateDocumentsTemplateParams>()("reportTemplates.sub.update"),
  {
    pathCreator: (path, params) => `${path.replace(":id", `${params.id}`)}`,
  },
);

export const downloadDocumentsTemplate = createRequest(
  getEndpoint("reportTemplates.sub.download"),
  {
    pathCreator: (path, params) => `${path.replace(":filename", `${params.filename}`)}`,
    fileRequest: true,
  },
);
