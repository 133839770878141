import { create } from "zustand";

const TIME_FOR_ANIMATION_CASTING = 300;

/**
 * A type that represents the props of an overlay component with the necessary isOpen prop.
 * It is used to ensure type safety when passing props to open function from useOverlayStore.
 * @template T The type of the overlay props.
 * @example
 * interface MyOverlayProps {
 *   title: string;
 *   onClose: () => void;
 * }
 *
 * const MyOverlay = function ({ title, onClose }: OverlayProps<MyOverlayProps>) {
 *  {...}
 * }
 */
export type OverlayProps<T> = T & { isOpen?: boolean };

type Overlay<Props = unknown> = {
  id: string;
  component: React.ComponentType<Props>;
  props: Props;
  isOpen: boolean;
};

interface OverlayState {
  overlays: Overlay[];
  /**
   * Opens an overlay by adding it to the overlays array.
   * @param component The component to be rendered as an overlay.
   * @param props Optional props to be passed to the overlay component. Make sure to enclose the overlay props types with the OverlayProps type.
   * @throws {Error} If an overlay with the same ID or component name already exists.
   */
  open: <T extends React.ComponentType<OverlayProps<unknown>>>(
    component: T,
    props?: React.ComponentProps<T>,
  ) => void;
  /**
   * Closes an overlay by updating its isOpen state to false and removing it from the overlays array after the animation timeout.
   * @param idOrComponent The ID of the overlay or the overlay component to be closed.
   * @throws {Error} If the provided ID or component name is not found in the overlays array.
   */
  close: <T extends React.ComponentType<OverlayProps<unknown>>>(idOrComponent: string | T) => void;
  /**
   * Closes all overlays by updating their isOpen state to false and removing them from the overlays array after the animation timeout.
   */
  closeAll: () => void;
  /**
   * Retrieves an overlay from the overlays array by its ID.
   * @param id The ID of the overlay to be retrieved.
   * @returns The overlay object if found, otherwise throws an error.
   * @throws {Error} If the provided ID is invalid or not found in the overlays array.
   */
  getOverlayById: (id: string) => Overlay;
  checkIfExists: <T extends React.ComponentType<OverlayProps<unknown>>>(
    idOrComponent: string | T,
  ) => boolean;
}

export const useOverlayStore = create<OverlayState>((set, get) => ({
  overlays: [],
  open: (component, props) => {
    const overlayId = (component as { id?: string }).id ?? component.name;

    const existingOverlay = get().overlays.find((overlay) => overlay.id === overlayId);

    if (existingOverlay) {
      throw new Error(
        `Overlay with "${overlayId}" already exists. Please choose a unique id or name for the component.`,
      );
    }

    set((state) => ({
      overlays: [
        ...state.overlays,
        { id: overlayId, component, props: props || undefined, isOpen: false },
      ],
    }));
    // This timeout allows React to mount the component first and then start the opening animation
    setTimeout(() => {
      set((state) => {
        const overlay = state.overlays.find((overlay) => overlay.id === overlayId);
        if (overlay) {
          overlay.isOpen = true;
        }
        return { overlays: [...state.overlays] };
      });
    }, 0);
  },
  close: (idOrComponent) => {
    let id: string;
    if (typeof idOrComponent === "string") {
      id = idOrComponent;
    } else {
      id = (idOrComponent as { id?: string }).id || idOrComponent.name;
    }

    const overlay = get().overlays.find((overlay) => overlay.id === id);
    if (!overlay) {
      throw new Error(`Overlay with ID or component name "${id}" not found.`);
    }

    set((state) => {
      const updatedOverlays = state.overlays.map((overlay) => {
        if (overlay.id === id) {
          return { ...overlay, isOpen: false };
        }
        return overlay;
      });
      return { overlays: updatedOverlays };
    });
    setTimeout(() => {
      set((state) => ({
        overlays: state.overlays.filter((overlay) => overlay.id !== id),
      }));
    }, TIME_FOR_ANIMATION_CASTING);
  },
  closeAll: () => {
    set((state) => ({
      overlays: state.overlays.map((overlay) => ({
        ...overlay,
        isOpen: false,
      })),
    }));
    setTimeout(() => {
      set({ overlays: [] });
    }, TIME_FOR_ANIMATION_CASTING);
  },
  getOverlayById: (id: string) => {
    if (!id || typeof id !== "string") {
      throw new Error("Invalid overlay ID. Please provide a valid string ID.");
    }
    const overlay = get().overlays.find((overlay) => overlay.id === id);
    if (!overlay) {
      throw new Error(`Overlay with ID "${id}" not found.`);
    }
    return overlay as Overlay;
  },
  checkIfExists: (idOrComponent) => {
    let id: string;
    if (typeof idOrComponent === "string") {
      id = idOrComponent;
    } else {
      id = (idOrComponent as { id?: string }).id || idOrComponent.name;
    }

    const overlay = get().overlays.find((overlay) => overlay.id === id);
    return !!overlay;
  },
}));
