import { Theme } from "@mui/material/styles";
import { getNestedValue } from "./getNestedValue";

// get nested color from material UI (theme.palette...)
// useful if you need i.e. color.main and color[600] as an option in the same custom material component
export const getNestedThemeColor = <T extends string>(theme: Theme, colorKey: T): string => {
  const color = getNestedValue(theme.palette, colorKey);

  if (!color && colorKey[0] === "#") {
    return colorKey;
  }

  if (!color) {
    throw new Error(`Color ${colorKey} not found in theme.palette`);
  }

  return ((color as typeof theme.palette.primary).main || color) as string;
};
