"use client";
import MUIIconButton, { IconButtonProps as MUIIconButtonProps } from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { forwardRef } from "react";

interface IconButtonProps extends MUIIconButtonProps {
  variant?: "contained" | "outlined" | "text";
}

const StyledContainedIconButton = styled(MUIIconButton)(({ theme, color }) => {
  const mainBackgroundColor =
    color && color !== "default" && color !== "inherit"
      ? theme.palette[color]?.main || theme.palette.secondary.main
      : color;
  const hoverBackgroundColor =
    color && color !== "default" && color !== "inherit"
      ? theme.palette[color]?.dark || theme.palette.secondary.dark
      : color;

  return {
    color: theme.palette.common.white,
    backgroundColor: mainBackgroundColor,
    "&:hover": {
      backgroundColor: hoverBackgroundColor,
    },
  };
});

const StyledOutlinedIconButton = styled(MUIIconButton)(({ theme, color }) => {
  const mainColor =
    color && color !== "default" && color !== "inherit"
      ? theme.palette[color]?.main || theme.palette.secondary.main
      : color;
  const borderColor = mainColor;

  return {
    color: mainColor,
    border: `1px solid ${borderColor}`,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  };
});

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(function IconButton(
  { children, variant = "text", color = "secondary", ...props },
  ref,
) {
  const Component =
    variant === "contained"
      ? (props: IconButtonProps) => <StyledContainedIconButton color={color} {...props} />
      : variant === "outlined"
        ? (props: IconButtonProps) => <StyledOutlinedIconButton color={color} {...props} />
        : MUIIconButton;

  return (
    <Component color={color} {...props} ref={ref}>
      {children}
    </Component>
  );
});
