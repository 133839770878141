"use client";

import { EA_UTILS } from "@ea/shared_types/next/ea.utils";
import { ReactNode, useContext } from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  FormGroupProps,
  FormHelperText,
} from "../materialUI";
import { EditableContext } from "./utils";

interface CustomFormControlLabelProps extends Omit<FormControlLabelProps, "control" | "label"> {}

type FormCheckboxProps = {
  name: string;
  label: ReactNode;
  dataTestidFormControl: string;
  dataTestidHelperText?: string;
  formControlLabelProps?: CustomFormControlLabelProps;
  formGroupProps?: FormGroupProps;
  checkboxProps?: CheckboxProps;
  defaultValue?: boolean;
  onChange?: CheckboxProps["onChange"];
};

export const FormCheckbox = ({
  name,
  label,
  defaultValue,
  dataTestidFormControl,
  dataTestidHelperText,
  formControlLabelProps,
  formGroupProps,
  checkboxProps,
  onChange: customOnChange,
}: FormCheckboxProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const editable = useContext(EditableContext);

  const fieldError = EA_UTILS.object.getNestedValue(errors, name) as FieldError | undefined;
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, onBlur, value } }) => (
        <FormGroup {...formGroupProps}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                onBlur={onBlur}
                disabled={!editable}
                checked={!!value}
                // https://github.com/mui/material-ui/issues/37753
                // The readonly attribute is not applicable to checkbox
                onChange={customOnChange || onChange}
                {...checkboxProps}
              />
            }
            label={label}
            data-testid={dataTestidFormControl}
            {...formControlLabelProps}
          />

          {fieldError && (
            <FormHelperText
              data-testid={!dataTestidHelperText && `${dataTestidFormControl}-helper-text`}
              error
            >
              {fieldError.message}
            </FormHelperText>
          )}
        </FormGroup>
      )}
    />
  );
};
