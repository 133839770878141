import { getInitialData } from "../../api/endpoints/runner";
import { createQuery } from "./apiHooks";

export const RUNNER_START_QUERY_KEY = ["Runner", "getInitial"];

export const useRunnerSession = createQuery(getInitialData, {
  queryKey: RUNNER_START_QUERY_KEY,
  // WORK: this one \/ is workaround for double query
  // staleTime: Infinity,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
});
