import {
  countGlobalVariables,
  countVariables,
  createGlobalVariable,
  createVariable,
  deleteVariable,
  getGlobalVariables,
  getVariables,
  updateGlobalVariable,
  updateVariable,
} from "../../api/endpoints";
import { createMutation, createQuery } from "./apiHooks";

export const useCreateVariable = createMutation(createVariable, {
  mutationKey: ["Variable", "Create"],
});

export const useUpdateVariable = createMutation(updateVariable, {
  mutationKey: ["Variable", "Update"],
});

export const useDeleteVariable = createMutation(deleteVariable, {
  mutationKey: ["Variable", "Delete"],
});

export const useGetVariables = createQuery(getVariables, {
  queryKey: ["Variable", "Get"],
});

export const useCountVariables = createQuery(countVariables, {
  queryKey: ["Variable", "Count"],
});

export const useGetGlobalVariables = createQuery(getGlobalVariables, {
  queryKey: ["GlobalVariable", "Get"],
});

export const useCountGlobalVariables = createQuery(countGlobalVariables, {
  queryKey: ["GlobalVariable", "Count"],
});

export const useCreateGlobalVariable = createMutation(createGlobalVariable, {
  mutationKey: ["GlobalVariable", "Create"],
});

export const useUpdateGlobalVariable = createMutation(updateGlobalVariable, {
  mutationKey: ["GlobalVariable", "Update"],
});
