import { Box, CircularProgress } from "@mui/material";
import { DataTestIds } from "../dataTestIds";

type PageLoaderProps = {
  relative?: boolean;
};

const absoluteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const relativeStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "80vh",
};

export const PageLoader = ({ relative = false }: PageLoaderProps) => {
  const boxStyle = relative ? relativeStyle : absoluteStyle;

  return (
    <Box data-testid={DataTestIds.LOADER} sx={boxStyle}>
      <CircularProgress color="secondary" />
    </Box>
  );
};
