import {
  createLocalizations,
  exportLocalizations,
  getLocalizations,
  importLocalizations,
  updateLocalizations,
} from "../../api/endpoints";
import { createMutation, createQuery } from "./apiHooks";

export const useCreateLocalizations = createMutation(createLocalizations, {
  mutationKey: ["Localizations", "Create"],
});

export const useGetLocalizations = createQuery(getLocalizations, {
  queryKey: ["Localizations", "Get"],
});

export const useUpdateLocalizations = createMutation(updateLocalizations, {
  mutationKey: ["Localizations", "Update"],
});

export const useExportLocalizations = createMutation(exportLocalizations, {
  mutationKey: ["Localizations", "Export"],
});

export const useImportLocalizations = createMutation(importLocalizations, {
  mutationKey: ["Localizations", "Import"],
});
