"use client";

import { GlobalVariableType, VariableType } from "@ea/shared_types/next/ea.enums";
import { GlobalVariable, Step, Variable } from "@ea/shared_types/next/ea.types";
import { PlainObject } from "@ea/shared_types/types";
import { useMemo } from "react";
import { useGetGlobalVariables, useGetSteps, useGetVariables } from "../../../hooks/api";
import { useNotification } from "../../../hooks/useNotification";
import { useTranslations } from "../../../translations/useTranslations";

type VariableOrGlobalVariable = Variable | GlobalVariable;

export interface VariableGroup {
  id: string;
  variables?: VariableOrGlobalVariable[];
  groups?: VariableGroup[];
}

const getVariableFilter = (parentType: string, type: VariableType, parentId?: number) => ({
  filter: {
    where: {
      parentType,
      type,
      ...(parentId && { parentId }),
    },
  },
});

export const useVariablesGroups = (scriptId: number | undefined) => {
  const { toast } = useNotification();
  const { t } = useTranslations("Toast");

  const { data: localVariables, isError: isErrorVariables } = useGetVariables(
    getVariableFilter("TaskScript", VariableType.Normal, scriptId),
    { enabled: !!scriptId },
  );

  const { data: dataSourceVariables, isError: isErrorDatasource } = useGetVariables(
    getVariableFilter("TaskScript", VariableType.DataSource, scriptId),
    { enabled: !!scriptId },
  );

  const { data: sequenceVariables, isError: isErrorSequences } = useGetVariables(
    getVariableFilter("Sequence", VariableType.Sequence),
    { enabled: !!scriptId },
  );

  const { data: steps, isError: isErrorSteps } = useGetSteps(
    { filter: { where: { taskScriptId: scriptId } } },
    { enabled: !!scriptId },
  );
  const { data: globalVariables, isError: isErrorGlobalVariables } = useGetGlobalVariables(
    { filter: { where: { taskScriptId: scriptId } } },
    { enabled: !!scriptId },
  );

  const globalConstants = useMemo(
    () => globalVariables?.items.filter((gv) => gv.type === GlobalVariableType.CONSTANT),
    [globalVariables],
  );
  const globalMutables = useMemo(
    () => globalVariables?.items.filter((gv) => gv.type === GlobalVariableType.MUTABLE),
    [globalVariables],
  );

  const getLinkedVariablesPerParent = (localVariables: Variable[] | undefined) =>
    localVariables?.reduce(
      (container, linkedVariable) => {
        if (!container[linkedVariable.parentId]) {
          container[linkedVariable.parentId] = [];
        }
        container[linkedVariable.parentId].push(linkedVariable);
        return container;
      },
      {} as PlainObject<Variable[]>,
    );

  const getDataSourceByGroup = (dataSourceVariables: Variable[] | undefined) =>
    dataSourceVariables?.reduce(
      (container, variable) => {
        // todo: after migration below line can be deleted
        if (!variable.groupName) {
          return container;
        }
        if (!container[variable.groupName]) {
          container[variable.groupName] = [];
        }
        container[variable.groupName].push(variable);
        return container;
      },
      {} as PlainObject<Variable[]>,
    );

  const linkedVariablesPerParent = useMemo(
    () => getLinkedVariablesPerParent(localVariables?.items),
    [localVariables],
  );

  const dataSourceByGroup = useMemo(
    () => getDataSourceByGroup(dataSourceVariables?.items),
    [dataSourceVariables],
  );

  const variablesGroups: VariableGroup[] = useMemo(() => {
    if (
      localVariables?.items &&
      sequenceVariables?.items &&
      dataSourceByGroup &&
      linkedVariablesPerParent
    ) {
      return [
        { id: "Script", variables: localVariables.items },
        {
          id: "DataSource",
          groups: Object.keys(dataSourceByGroup).map((key) => ({
            id: key,
            variables: dataSourceByGroup[key],
          })),
        },
        { id: "Sequence", variables: sequenceVariables.items },
        {
          id: "Scripts",
          groups: steps?.items
            .map((s: Step) => {
              const linkName = s.linkName!;
              const linkedVariables = linkedVariablesPerParent[s.linkedScriptId!] || [];
              if (linkedVariables.length === 0) return null;
              return {
                id: linkName,
                variables: linkedVariables,
              };
            })
            .filter(Boolean) as VariableGroup[],
        },
        {
          id: "Global",
          groups: [
            { id: "Constant", variables: globalConstants },
            { id: "Variable", variables: globalMutables },
          ],
        },
      ];
    }
    return [];
  }, [
    localVariables,
    sequenceVariables,
    dataSourceByGroup,
    linkedVariablesPerParent,
    steps,
    globalConstants,
    globalMutables,
  ]);

  const hasError =
    isErrorVariables &&
    isErrorSequences &&
    isErrorDatasource &&
    isErrorSteps &&
    isErrorGlobalVariables;

  if (hasError) {
    toast.error(t("failedToLoadVariablesForTheScriptInCodeEditor"));
  }

  return variablesGroups;
};
