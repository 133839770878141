"use client";
import { useTheme } from "@mui/material";
import { ChartsGridProps, CurveType, LineChart as MUILineChart, ScaleName } from "@mui/x-charts";
import { useMemo } from "react";

type LineChartProps = {
  data:
    | {
        [key: string]: number[];
      }
    | number[];
  xData: string[] | number[];
  xLabel?: string;
  yLabel?: string;
  seriesLabel?: string;
  scaleType?: ScaleName;
  curve?: CurveType;
  width?: number;
  height?: number;
  area?: boolean;
  gridProps?: ChartsGridProps;
  isLoading?: boolean;
};

export const LineChart = ({
  data,
  xData,
  xLabel,
  yLabel,
  seriesLabel,
  scaleType = "point",
  curve = "linear",
  width,
  height,
  gridProps = { vertical: true, horizontal: true },
  isLoading,
  area = false,
}: LineChartProps) => {
  const theme = useTheme();

  const series = useMemo(() => {
    const lineColors = [
      theme.palette.secondary[500],
      theme.palette.error[500],
      theme.palette.success[500],
      theme.palette.warning[500],
      theme.palette.neutral[500],
    ];

    return Array.isArray(data)
      ? [
          {
            label: seriesLabel,
            data: data,
            curve: curve,
            area: area,
            color: lineColors[0],
          },
        ]
      : Object.keys(data).map((key, index) => ({
          id: index,
          label: key,
          data: data[key],
          curve: curve,
          area: area,
          color: lineColors[index % lineColors.length],
        }));
  }, [data, seriesLabel, curve, area, theme.palette]);

  return (
    <MUILineChart
      loading={isLoading}
      xAxis={[{ label: xLabel, data: xData, scaleType: scaleType }]}
      yAxis={[{ label: yLabel }]}
      series={series}
      grid={gridProps}
      width={width}
      height={height}
    />
  );
};
