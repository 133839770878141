import { createRequest, getEndpoint } from "../requestCreator";
import { requestTags } from "../requestTags";

export const getSchedulers = createRequest(getEndpoint("schedulers.sub.get"), {
  next: { tags: [requestTags.schedulersRequestTags.getSchedulers] },
});
export const createScheduler = createRequest(getEndpoint("schedulers.sub.create"));
export const closeSchedulers = createRequest(getEndpoint("schedulers.sub.close"));
export const cloneScheduler = createRequest(getEndpoint("schedulers.sub.clone"));
export const importScheduler = createRequest(getEndpoint("schedulers.sub.import"));
export const updateScheduler = createRequest(getEndpoint("schedulers.sub.update"));
export const runNowScheduler = createRequest(getEndpoint("schedulers.sub.runNow"), {
  rawResponseText: true,
});
export const terminateProjectJob = createRequest(getEndpoint("schedulers.sub.terminateProject"));
export const runProjectJob = createRequest(getEndpoint("schedulers.sub.runProject"));
export const getCurrentExecution = createRequest(getEndpoint("schedulers.sub.checkProjectRun"));
export const getMappings = createRequest(getEndpoint("schedulers.sub.mappings.sub.get"));
