"use client";
import {
  CircularProgress,
  Menu,
  MenuItem,
  SxProps,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { ExtendedMenuItemData, nestedMenuItemsFromObject } from "./utils";

interface NestedSelectProps {
  menuItems: ExtendedMenuItemData[];
  label: string;
  selectedValue?: string;
  sx?: SxProps;
  onChange?: (value: string) => void;
  textFieldProps?: TextFieldProps;
  loading?: boolean;
}

export const NestedSelect = ({
  menuItems,
  selectedValue,
  label,
  sx,
  onChange,
  textFieldProps,
  loading = false,
}: NestedSelectProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);

  const handleMenuItemClick = useCallback(
    (value: string) => {
      if (onChange) {
        onChange(value);
      }
      setAnchorEl(null);
    },
    [onChange],
  );

  const nestedItems = useMemo(() => {
    if (loading) {
      return (
        <MenuItem
          sx={{
            minWidth: "14rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={24} color="secondary" />
        </MenuItem>
      );
    }

    return nestedMenuItemsFromObject({
      menuItemsData: menuItems,
      isOpen: open,
      handleClose: () => setAnchorEl(null),
      handleClick: handleMenuItemClick,
    });
  }, [menuItems, open, handleMenuItemClick, loading]);

  const formatValueToLabel = (value: string): string => {
    if (!value) return "";

    // cleaning value from unnecessary characters that we set in PickerSelection form
    const cleanedStr = value.replace(/`|\$\{(.+)\}/g, "$1");
    const formattedLabel = cleanedStr.replace(/\./g, "/");

    return formattedLabel;
  };

  const displayValue = selectedValue ? formatValueToLabel(selectedValue) : "";

  return (
    <>
      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        {nestedItems}
      </Menu>

      <TextField
        select
        value={selectedValue || ""}
        label={label}
        sx={sx}
        SelectProps={{
          open: false,
          onOpen: (e) => setAnchorEl(e.currentTarget),
        }}
        size="small"
        fullWidth={true}
        variant="outlined"
        {...textFieldProps}
      >
        {selectedValue ? <MenuItem value={selectedValue}>{displayValue}</MenuItem> : <span></span>}
      </TextField>
    </>
  );
};
