"use client";

import { EA_UTILS } from "@ea/shared_types/next/ea.utils";
import { ReactNode, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { MenuItem, Select, SelectProps } from "../materialUI";
import { EditableContext } from "./utils";

type OptionType = {
  label: ReactNode;
  value: string | number;
};

type FormTextFieldProps = SelectProps & {
  name: string;
  size?: "small" | "medium";
  dataTestid: string;
  options: OptionType[];
};

export const FormSelect = ({
  name,
  size = "small",
  fullWidth = true,
  variant = "outlined",
  dataTestid,
  options,
  ...props
}: FormTextFieldProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const fieldError = EA_UTILS.object.getNestedValue(errors, name);

  const editable = useContext(EditableContext);
  return (
    <Select
      {...register(name)}
      size={size}
      variant={variant}
      fullWidth={fullWidth}
      error={!!fieldError}
      data-testid={dataTestid}
      readOnly={!editable}
      // TS Performance improvement
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(props as any)}
    >
      {options.map((item, index) => (
        <MenuItem value={item.value} key={index}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
};
