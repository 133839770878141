import { Alert, Box, Dialog, DialogActions, DialogContent, DialogProps } from "@mui/material";
import { BUTTON_SIZES, Button, ButtonProps } from "../Buttons/Button";
import { CustomDialogTitle } from "./CustomDialogTitle";

type ConfirmDialogProps = DialogProps & {
  title: string;
  onClose: () => void;
  onConfirm: () => void;
  withCloseIcon?: boolean;
  open: boolean;
  type?: "warning" | "success" | "error";
  controls?: {
    cancel?: string;
    confirm?: string;
  };
  loading?: boolean;
  confirmButtonProps?: ButtonProps;
};

export function ConfirmDialog({
  children,
  title,
  onClose,
  onConfirm,
  withCloseIcon = false,
  type,
  loading = false,
  controls,
  sx,
  open,
  confirmButtonProps,
  ...props
}: ConfirmDialogProps) {
  return (
    <Dialog onClose={onClose} open={open} {...props}>
      <CustomDialogTitle
        withCloseIcon={withCloseIcon}
        onClose={onClose}
        sx={{ fontSize: "1em", color: "neutral.600", fontWeight: "500", m: 1.25 }}
      >
        {title}
      </CustomDialogTitle>
      <DialogContent
        sx={{
          width: "450px",
          minHeight: "5rem",
          display: "flex",
          alignItems: "flex-start",
          padding: "0 10%",
          ...sx,
        }}
      >
        {type === "warning" && (
          <Alert severity="warning" title="Warning" sx={{ backgroundColor: "unset" }} />
        )}
        {type === "success" && (
          <Alert severity="success" title="Success" sx={{ backgroundColor: "unset" }} />
        )}
        {type === "error" && (
          <Alert severity="error" title="Error" sx={{ backgroundColor: "unset" }} />
        )}
        <Box ml={2} fontWeight={500}>
          {children}
        </Box>
      </DialogContent>
      {controls && (
        <DialogActions sx={{ m: 1.5 }}>
          {controls.cancel && (
            <Button color="secondary" width={BUTTON_SIZES.STANDARD} onClick={onClose}>
              {controls.cancel}
            </Button>
          )}
          {controls.confirm && (
            <Button
              color="secondary"
              onClick={onConfirm}
              variant="contained"
              disabled={loading}
              width={BUTTON_SIZES.STANDARD}
              {...confirmButtonProps}
            >
              {controls.confirm}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}
