import { downloadDocumentation, updateDocumentation } from "../../api/endpoints";
import { createMutation } from "./apiHooks";

export const useUpdateDocumentation = createMutation(updateDocumentation, {
  mutationKey: ["Documentations", "UpdateDocumentations"],
});

export const useDownloadDocumentations = createMutation(downloadDocumentation, {
  mutationKey: ["Documentations", "DownloadDocumentations"],
});
