import { StyledComponent } from "@emotion/styled";
import { styled } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { Paper, PaperProps } from "../materialUI";

export const StyledPaper: StyledComponent<PaperProps & { theme?: Theme }, object> = styled(Paper)(
  ({ theme }) => `
  display: flex;
  padding: 1rem;
  border-radius: 8px;
  justify-content: space-between;
  background-color: ${theme.palette.neutral[50]};
  cursor: pointer;
  `,
);
