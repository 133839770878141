import AddIcon from "@mui/icons-material/Add";
import CopyAllIcon from "@mui/icons-material/CopyAllOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/DownloadOutlined";
import EditIcon from "@mui/icons-material/Edit";
import FolderOpenIcon from "@mui/icons-material/FolderOpenOutlined";
import LinkIcon from "@mui/icons-material/Link";
import RefreshIcon from "@mui/icons-material/Refresh";
import UploadFileIcon from "@mui/icons-material/UploadFileOutlined";
import UploadIcon from "@mui/icons-material/UploadOutlined";
import { forwardRef, RefObject } from "react";
import { ButtonProps, IconButton } from "../materialUI";
import { StyledActionButton } from "./ActionsBar.styles";

export type ActionProps = Omit<ButtonProps, "key"> & { key?: string | null };
export type VariantActionProps = Omit<ActionProps, "variant"> & {
  variant?: "button" | "icon";
};

export const Action = forwardRef(
  ({ children, ...props }: ActionProps, ref: RefObject<HTMLButtonElement>) => (
    <StyledActionButton ref={ref} {...props}>
      {children}
    </StyledActionButton>
  ),
);

export const VariantAction = ({
  children,
  variant,
  startIcon,
  onClick,
  ...props
}: VariantActionProps) =>
  variant === "icon" ? (
    <IconButton onClick={onClick}>{startIcon}</IconButton>
  ) : (
    <Action startIcon={startIcon} onClick={onClick} {...props}>
      {children}
    </Action>
  );

export const AddAction = ({ children, ...props }: VariantActionProps) => (
  <VariantAction startIcon={<AddIcon />} {...props}>
    {children}
  </VariantAction>
);

export const DownloadAction = ({ children, ...props }: ActionProps) => (
  <Action startIcon={<DownloadIcon />} {...props}>
    {children}
  </Action>
);

export const UploadAction = ({
  children,
  file = false,
  ...props
}: ActionProps & { file?: boolean }) => (
  <Action startIcon={file ? <UploadFileIcon /> : <UploadIcon />} {...props}>
    {children}
  </Action>
);

export const LinkAction = ({ children, ...props }: ActionProps) => (
  <Action startIcon={<LinkIcon />} {...props}>
    {children}
  </Action>
);

export const CopyAction = ({ children, ...props }: ActionProps) => (
  <Action startIcon={<CopyAllIcon />} {...props}>
    {children}
  </Action>
);

export const OpenAction = ({ children, ...props }: ActionProps) => (
  <Action startIcon={<FolderOpenIcon />} {...props}>
    {children}
  </Action>
);

export const DeleteAction = ({ children, ...props }: VariantActionProps) => (
  <VariantAction startIcon={<DeleteIcon />} {...props}>
    {children}
  </VariantAction>
);

export const RefreshAction = ({ children, ...props }: VariantActionProps) => (
  <VariantAction startIcon={<RefreshIcon />} {...props}>
    {children}
  </VariantAction>
);

export const EditAction = ({ children, ...props }: VariantActionProps) => (
  <VariantAction startIcon={<EditIcon />} {...props}>
    {children}
  </VariantAction>
);
