import {
  downloadCapturedFile,
  getCapturedFiles,
  getExecutionLogsStats,
  getLogOverview,
  getProjectExecutionLogsStats,
  terminateExecution,
} from "../../api/endpoints";
import { createMutation, createQuery } from "./apiHooks";

export const useGetExecutionLogsStats = createQuery(getExecutionLogsStats, {
  queryKey: ["ExecutionLogs", "ExecutionLogsStats"],
});

export const useGetExecutionLogsOverview = createQuery(getLogOverview, {
  queryKey: ["ExecutionLogs", "Overview"],
});

export const useGetProjectExecutionLogsStats = createQuery(getProjectExecutionLogsStats, {
  queryKey: ["ExecutionLogs", "ProjectExecutionLogsStats"],
});
export const useGetCapturedFiles = createQuery(getCapturedFiles, {
  queryKey: ["ExecutionLogs", "CaptureFile"],
});
export const useDownloadCapturedFile = createMutation(downloadCapturedFile, {
  mutationKey: ["CaptureFile", "Download"],
});

export const useTerminateExecution = createMutation(terminateExecution, {
  mutationKey: ["Execution", "Terminate"],
});
