import { createGuard, updateGuard } from "../../api/endpoints";
import { createMutation } from "./apiHooks";

export const useCreateGuard = createMutation(createGuard, {
  mutationKey: ["Guards", "Create"],
});

export const useUpdateGuard = createMutation(updateGuard, {
  mutationKey: ["Guards", "Update"],
});
