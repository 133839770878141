"use client";

import { useContext, useState } from "react";
import { VisibilityIcon, VisibilityOffIcon } from "../../icons";
import { IconButton, InputAdornment } from "../materialUI";
import { FormTextField } from "./FormTextField";
import { EditableContext } from "./utils";

type PasswordTextFieldProps = {
  name: string;
  label: string;
  dataTestid: string;
  disabled?: boolean;
  required?: boolean;
  showVisibilityToggle?: boolean;
};

export const FormPasswordTextField = ({
  name,
  label,
  dataTestid,
  disabled,
  required = true,
  showVisibilityToggle = true,
}: PasswordTextFieldProps) => {
  const [isPasswordVisible, setPasswordVisibility] = useState(false);

  const editable = useContext(EditableContext);
  return (
    <FormTextField
      required={required}
      type={isPasswordVisible ? "text" : "password"}
      name={name}
      dataTestid={dataTestid}
      label={label}
      disabled={disabled}
      InputProps={{
        readOnly: !editable,
        endAdornment: showVisibilityToggle && (
          <InputAdornment position="end">
            <IconButton
              disabled={disabled}
              onClick={() => setPasswordVisibility((v) => !v)}
              edge="end"
            >
              {isPasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
