import {
  addNewCodeTemplate,
  addNewCodeTemplateGroup,
  exportCodeTemplatesFile,
  getCodeTemplateGroups,
  getCodeTemplates,
  updateCodeTemplateGroup,
  updateNewCodeTemplate,
  uploadCodeTemplatesFile,
} from "../../api/endpoints";
import { createMutation, createQuery } from "./apiHooks";

export const useUploadCodeTemplatesFile = createMutation(uploadCodeTemplatesFile, {
  mutationKey: ["CodeTemplates", "Upload"],
});

export const useGetCodeTemplateGroups = createQuery(getCodeTemplateGroups, {
  queryKey: ["CodeTemplatesGroups", "Get"],
});

export const useGetCodeTemplates = createQuery(getCodeTemplates, {
  queryKey: ["CodeTemplates", "Get"],
});

export const useAddNewCodeTemplate = createMutation(addNewCodeTemplate, {
  mutationKey: ["CodeTemplates", "Create"],
});

export const useUpdateCodeTemplate = createMutation(updateNewCodeTemplate, {
  mutationKey: ["CodeTemplates", "Update"],
});

export const useExportCodeTemplatesFile = createMutation(exportCodeTemplatesFile, {
  mutationKey: ["CodeTemplates", "Export"],
});

export const useAddNewCodeTemplateGroup = createMutation(addNewCodeTemplateGroup, {
  mutationKey: ["CodeTemplatesGroup", "Create"],
});

export const useUpdateCodeTemplateGroup = createMutation(updateCodeTemplateGroup, {
  mutationKey: ["CodeTemplatesGroup", "Update"],
});
