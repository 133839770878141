import { createRequest, getEndpoint } from "../requestCreator";

export const getVariables = createRequest(getEndpoint("variables.sub.get"));
export const countVariables = createRequest(getEndpoint("variables.sub.count"));
export const createVariable = createRequest(getEndpoint("variables.sub.create"));
export const updateVariable = createRequest(getEndpoint("variables.sub.update"));
export const deleteVariable = createRequest(getEndpoint("variables.sub.delete"));

export const getGlobalVariables = createRequest(getEndpoint("globalVariables.sub.get"));
export const deleteGlobalVariable = createRequest(getEndpoint("globalVariables.sub.delete"));
export const countGlobalVariables = createRequest(getEndpoint("globalVariables.sub.count"));
export const createGlobalVariable = createRequest(getEndpoint("globalVariables.sub.create"));
export const updateGlobalVariable = createRequest(getEndpoint("globalVariables.sub.update"));
