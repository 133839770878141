import { FORMDATA_FIELD_NAMES } from "@ea/shared_types/next/ea.consts";
import { createRequest, getEndpoint, getEndpointWithClientParams } from "../requestCreator";

export const getDatasource = createRequest(getEndpoint("dataSources.sub.get"));
export const countDatasource = createRequest(getEndpoint("dataSources.sub.count"));
export const deleteDatasource = createRequest(getEndpoint("dataSources.sub.delete"));
export const downloadDatasource = createRequest(getEndpoint("dataSources.sub.download"), {
  fileRequest: true,
});

export const getDatasourceTemplate = createRequest(getEndpoint("dataSources.sub.generate"), {
  fileRequest: true,
});

export const uploadDatasource = createRequest(
  getEndpointWithClientParams<{ files: File[] }>()("dataSources.sub.local.sub.upload"),
  {
    useContentType: false,
    bodyBuilder: (params) => {
      const formData = new FormData();
      const { files } = params || {};
      if (files) {
        files.forEach((file: File) =>
          formData.append(FORMDATA_FIELD_NAMES.datasources, file, file.name),
        );
      }
      return formData;
    },
  },
);

export const uploadGlobalExcel = createRequest(
  getEndpointWithClientParams<{ files: File[]; projectIds: number[] }>()(
    "dataSources.sub.global.sub.upload",
  ),
  {
    useContentType: false,
    bodyBuilder: (params) => {
      const formData = new FormData();
      const { files, projectIds } = params || {};
      if (files) {
        files.forEach((file: File) =>
          formData.append(FORMDATA_FIELD_NAMES.datasources, file, file.name),
        );
      }
      formData.append("projectIds", projectIds.map((id) => id.toString()).join(","));
      return formData;
    },
  },
);

export const updateGlobalExcel = createRequest(
  getEndpointWithClientParams<{ files: File[]; projectIds: number[] }>()(
    "dataSources.sub.global.sub.update",
  ),
  {
    useContentType: false,
    bodyBuilder: (params) => {
      const formData = new FormData();
      const { files, projectIds } = params || {};
      if (files) {
        files.forEach((file: File) =>
          formData.append(FORMDATA_FIELD_NAMES.datasources, file, file.name),
        );
      }
      formData.append("projectIds", projectIds.map((id) => id.toString()).join(","));
      return formData;
    },
  },
);

export const assignGlobalExcelToScript = createRequest(
  getEndpoint("dataSources.sub.global.sub.assignToScript"),
);

export const unassignGlobalExcelFromScript = createRequest(
  getEndpoint("dataSources.sub.global.sub.unassignFromScript"),
);
