import { Box, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";
import { SIZE, getPaddingValue } from "./Drawer";

export type DrawerContentProps = {
  children?: ReactNode;
  sx?: SxProps<Theme>;
  pxSize?: SIZE;
  py?: string;
};

export const DrawerContent = ({
  children,
  sx,
  pxSize = "lg",
  py = "2.5rem",
}: DrawerContentProps) => {
  return (
    <Box sx={{ padding: getPaddingValue(pxSize, py), overflowY: "auto", height: "100%", ...sx }}>
      {children}
    </Box>
  );
};
