import { getPlatforms, getPlatformsMeta } from "../../api/endpoints";
import { createQuery } from "./apiHooks";

export const useGetPlatforms = createQuery(getPlatforms, {
  queryKey: ["Platforms", "Get"],
});

const getAdministrationMeta = () =>
  getPlatformsMeta(null, {
    pathParams: () => ({
      platformType: "administration",
    }),
  });

export const useGetAdministrationMeta = createQuery(getAdministrationMeta, {
  queryKey: ["Platforms", "GetAdministrationMeta"],
});
