"use client";
import { useTheme } from "@mui/material";
import { DataTestIds } from "../dataTestIds";
import { EditIcon } from "../icons";
import { useTranslations } from "../translations/useTranslations";
import { Action, ActionProps } from "./ActionsBar";
import { CancelEditingDialog } from "./CancelEditingDialog";
import { useOverlayStore } from "./OverlayManager";

export type EditModeButtonProps = {
  editable: boolean;
  onClose: () => void;
  disableEditing: () => void;
  setEditable: (editable: boolean) => void;
  additionalButtonProps?: ActionProps;
};

export const EditModeButton = ({
  editable,
  onClose,
  disableEditing,
  setEditable,
  additionalButtonProps,
}: EditModeButtonProps) => {
  const theme = useTheme();
  const { open } = useOverlayStore();
  const { t } = useTranslations("EditMode");

  return (
    <Action
      sx={{
        backgroundColor: editable ? theme.palette.warning[500] : undefined,
        color: editable ? theme.palette.common.white : undefined,
        ":hover": { backgroundColor: editable ? theme.palette.warning[600] : undefined },
        mt: 2,
      }}
      startIcon={<EditIcon />}
      onClick={() => {
        if (!editable) {
          setEditable(true);
        } else {
          open(CancelEditingDialog, {
            disableEditing: disableEditing,
            onClose: onClose,
          });
        }
      }}
      data-testid={DataTestIds.EDITABLEAREA_BUTTON_EDIT}
      {...additionalButtonProps}
    >
      {t("edit")}
    </Action>
  );
};
