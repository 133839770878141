"use client";
import { FieldValues, RegisterOptions, useFormContext } from "react-hook-form";
import { Input, InputProps } from "../materialUI";

type FormInputProps = InputProps & {
  name: string;
  dataTestid: string;
  options?: RegisterOptions<FieldValues, string>;
};

export const FormHiddenInput = ({ name, dataTestid, options, ...props }: FormInputProps) => {
  const { register } = useFormContext();

  return <Input {...register(name, options)} {...props} type="hidden" data-testid={dataTestid} />;
};
