import {
  countSystemDefinitions,
  createSystemDefinition,
  deleteSystemDefinition,
  getSystemDefinitions,
  updateSystemDefinition,
} from "../../api/endpoints";
import { createMutation, createQuery } from "./apiHooks";

export const SYSTEM_DEFINITIONS_QUERY_KEY = ["Systems", "Get"];

export const useCreateSystemDefinition = createMutation(createSystemDefinition, {
  mutationKey: ["Systems", "Create"],
});

export const useUpdateSystemDefinition = createMutation(updateSystemDefinition, {
  mutationKey: ["Systems", "Update"],
});

export const useGetSystemDefinitions = createQuery(getSystemDefinitions, {
  queryKey: SYSTEM_DEFINITIONS_QUERY_KEY,
});

export const useCountSystemDefinitions = createQuery(countSystemDefinitions, {
  queryKey: ["Systems", "Count"],
});

export const useDeleteSystemDefinition = createMutation(deleteSystemDefinition, {
  mutationKey: ["Systems", "Delete"],
});
